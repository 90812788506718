import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelSmall,
  ParagraphSmall,
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { Notification, KIND } from "baseui/notification";
import { editorBtnOptions as HomeComponentBuilderOptions, editorBtnMainOptions as CardBackBuilderOptions } from '../../../data/cardBuilderOptions';
import { renderInputField, renderSelectField } from './common-components/CustomRenderFunctions';
import styled from '@emotion/styled';

const outerGridOverride = {
  Grid: {
    style: () => ({
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '30px !important',
    }),
  },
};

const HomeComponentContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;
`;

const getCardBackOptions = () => {
  let CardBackOptions = [];
  CardBackBuilderOptions.map(el => {
    HomeComponentBuilderOptions.map(el1 => {
      if (el1.parent === el.id) {
        CardBackOptions.push(el1)
      }
    })
  });
  return CardBackOptions;
}

const getHomeComponentOptions = () => {
  return [
    {
      __typename: 'Slide',
      name: 'Slider',
    },
    ...HomeComponentBuilderOptions.filter((el) => el.addInHomeComponent)
  ];
}

const query = () => gql`
    query original_organization_settings {
      original_organization_settings{
        id
        is_api_settings_on
        is_card_link_button_css_on
        is_library_nav_on
        is_rewards_nav_on
        is_app_env_on
        is_login_settings_on
        is_testers_on
        is_channel_advanced_on
        is_subscription_on
        is_sales_button_on
        is_group_color_settings_on
        is_group_radius_settings_on
        is_card_back_settings_on
        is_card_back_filters_on
        card_back_hidden_modules
        hidden_home_components
      }
    }
`;

const mutation = {
  update: gql`
    mutation updateOrganizationSetting(
      $id: ID!
      $is_api_settings_on: Boolean
      $is_card_link_button_css_on: Boolean
      $is_library_nav_on: Boolean
      $is_rewards_nav_on: Boolean
      $is_app_env_on: Boolean
      $is_login_settings_on: Boolean
      $is_testers_on: Boolean
      $is_channel_advanced_on: Boolean
      $is_subscription_on: Boolean
      $is_sales_button_on: Boolean
      $is_group_color_settings_on: Boolean
      $is_group_radius_settings_on: Boolean
      $is_card_back_settings_on: Boolean
      $is_card_back_filters_on: Boolean
      $card_back_hidden_modules: String
      $hidden_home_components: String
    ) {
      updateOrganizationSetting(
        id: $id
        is_api_settings_on: $is_api_settings_on
        is_card_link_button_css_on: $is_card_link_button_css_on
        is_library_nav_on: $is_library_nav_on
        is_rewards_nav_on: $is_rewards_nav_on
        is_app_env_on: $is_app_env_on
        is_login_settings_on: $is_login_settings_on
        is_testers_on: $is_testers_on
        is_channel_advanced_on: $is_channel_advanced_on
        is_subscription_on: $is_subscription_on
        is_sales_button_on: $is_sales_button_on
        is_group_color_settings_on: $is_group_color_settings_on
        is_group_radius_settings_on: $is_group_radius_settings_on
        is_card_back_settings_on: $is_card_back_settings_on
        is_card_back_filters_on: $is_card_back_filters_on
        card_back_hidden_modules: $card_back_hidden_modules
        hidden_home_components: $hidden_home_components
      ) {
        id
      }
    }
  `,
  delete: gql`
    mutation deleteOrganizationTester($id: ID!) {
      deleteOrganizationTester(id: $id)
    }
  `
};


const OP = [
  {
    key: 'is_rewards_nav_on',
    display: '[NAV] Rewards'
  },
  {
    key: 'is_library_nav_on',
    display: '[NAV] Library'
  },
  {
    key: 'is_api_settings_on',
    display: '[SETTINGS] Api Settings'
  },
  {
    key: 'is_app_env_on',
    display: '[SETTINGS] App Env Options'
  },
  {
    key: 'is_login_settings_on',
    display: '[SETTINGS] Login Page Settings'
  },
  {
    key: 'is_testers_on',
    display: '[SETTINGS] Tester Settings'
  },
  {
    key: 'is_card_back_settings_on',
    display: '[CARD] Builder Settings Tab'
  },
  {
    key: 'is_channel_advanced_on',
    display: '[CHANNEL] Advanced Settings'
  },
  {
    key: 'is_sales_button_on',
    display: '[CHANNEL] Sales Button Option'
  },
  {
    key: 'is_subscription_on',
    display: '[CHANNEL] Subscription Option'
  },
  {
    key: 'is_group_color_settings_on',
    display: '[GROUP] Color Settings'
  },
  {
    key: 'is_group_radius_settings_on',
    display: '[GROUP] Border Radius Settings'
  },
  {
    key: 'is_card_link_button_css_on',
    display: '[CARD LINK BUTTON] Custom Css Options'
  }
]

const fields = [
  {
    key: 'hidden_home_components',
    display: 'Hidden Home Components',
    type: 'multi_checkbox',
    valueKey: '__typename',
    labelKey: 'name',
    isMultiSelect: true,
    getOptions: () => {
      return getHomeComponentOptions();
    },
    extractValues: (values) => {
      return values && values.split(',') || [];
    },
    formatValue: (values) => {
      return values.toString();
    },
    extractValue: (value) => {
      return value.__typename;
    }
  },
  {
    key: 'card_back_hidden_modules',
    display: 'Card Back Hidden Modules',
    description: '',
    type: 'select',
    hasHr: false,
    valueKey: '__typename',
    labelKey: 'name',
    isMultiSelect: true,
    getOptions: () => {
      return getCardBackOptions();
    },
    extractValues: (values) => {
      return values && values.split(',') || [];
    },
    formatValue: (values) => {
      return values.toString();
    },
    extractValue: (value) => {
      return value.__typename;
    }
  }

]


const Testers = ({ organization, refetch }) => {
  const [css, theme] = useStyletron();
  const [options, setOptions] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [fieldValues, setFieldValues] = React.useState({});
  const [saveError, setSaveError] = React.useState(false);

  const fetch = (loading = true) => {
    setLoading(true);
    client.query({
      query: query(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setOptions(data.original_organization_settings);
      setLoading(false);
    })
  };

  const save = () => {
    setUpdateLoading(true);
    setSaved(false);
    setSaveError(false);
    let variables = {
      ...options
    }

    client.mutate({
      mutation: mutation.update,
      variables
    }).then(() => {
      setUpdateLoading(false);
      setSaved(true);
      fetch();
      refetch();
    }).catch(err => {
      setUpdateLoading(false);
      setSaveError(true);
    })
  }

  React.useEffect(() => {
    fetch();
  }, []);


  const onChangeOptions = (key, value) => {
    let o1 = JSON.parse(JSON.stringify(options));
    o1[key] = value;
    setOptions(o1);
  }

  const renderMultiSelectCheckbox = (el) => {
    const checkBoxOptions = el.getOptions();
    const selectedOptions = el.extractValues(options[el.key]);
    const checkIsChecked = (val) => {
      const isChecked = selectedOptions.find(el1 => el1 === val);
      return isChecked && true || false;
    }
    const onChangeVal = (value) => {
      const isChecked = checkIsChecked(value);
      let selectedOptionsArr = selectedOptions
      if (isChecked) {
        selectedOptionsArr = selectedOptions.filter(el1 => el1 !== value);
      } else {
        selectedOptionsArr.push(value);
      }
      onChangeOptions(el.key, el.formatValue(selectedOptionsArr));
    }

    return (
      <Cell
        overrides={cellPaddingOverrides}
        span={12}
      >
        <LabelSmall
          style={{
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          {el.display}
        </LabelSmall>
        <HomeComponentContainer>
          {
            checkBoxOptions.map(el2 => {
              const val = el.extractValue(el2);
              const onChange = () => {
                onChangeVal(val)
              }
              const isChecked = checkIsChecked(val);
              return renderCheckBox(el2.name, isChecked, onChange);
            })
          }
        </HomeComponentContainer>
      </Cell>
    )
  }

  const renderCheckBox = (name, isChecked, onChange) => {
    return (
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <ParagraphSmall style={{ marginBottom: '26px', marginRight: '32px' }}> {name}</ParagraphSmall>
      </Checkbox>
    )
  }

  if (loading) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(6).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                {new Array(1).fill('').map(item => <Skeleton
                  width="90%"
                  height="105px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  };
  
  return (
    <Grid overrides={outerGridOverride}>
      {OP.map((_o, index) => {
        const onChange = e => {
          onChangeOptions(_o.key, e.target.checked);
        }
        return <Cell overrides={cellPaddingOverrides} key={index} span={12}>
          {renderCheckBox(_o.display, options[_o.key], onChange)}
        </Cell>
      })}
      {
        fields.map(renderMultiSelectCheckbox)
      }
      <Cell span={12} overrides={cellPaddingOverrides}>
        <Button
          isLoading={updateLoading}
          onClick={() => {
            save();
          }}
          isSelected
        >
          Save
        </Button>
        {saved && <Notification kind={KIND.positive} autoHideDuration={5000}>
          Settings saved!
        </Notification>}
        {saveError && <Notification kind={KIND.negative} autoHideDuration={5000}>
          Error in saving settings
        </Notification>}
      </Cell>
    </Grid>
  )
};

export default Testers;