import * as React from 'react';
import { Plus, Upload, Grab, Filter, ArrowDown } from 'baseui/icon';
import svgIcons from '../src/svg';
import styled from '@emotion/styled';

const StyledIcon = styled.img`
  width: 24px;
  height: 16px;
  margin-bottom: 0px;
`;
// import { 
//   CardLinkDeckIcon, 
//   DashboardDeckIcon, 
//   ImageDeckIcon, 
//   InsertLinkDeckIcon, 
//   PhoneLinkDeckIcon, 
//   TableDeckIcon 
// } from '../src/svg';


// editorBtnOptions are the options available for left side of menu of card back editor
// for example Button
// the format of Button simple fields you can see below object
// {
//   name: 'URL Button',
//   display: 'URL Button',
//   id: 'button_1',
//   icon: <Plus></Plus>,
//   parent: 'option_5',
//   __typename: 'ButtonCardModule',
//   addInNewCommunication: true,
//   inputs: [
//     {
//       type: 'Input',
//       key: 'title',
//       dataType: 'String',
//       title: 'Button Text',
//       value: '',
//       placeholder: 'Enter Button Text',
//     },
//     {
//       type: 'Input',
//       key: 'url',
//       dataType: 'String',
//       title: 'Button URL',
//       value: '',
//       placeholder: 'Enter Button URL',
//     },
//   ],
// }

// __typename is type of module if you look button in graphql this __typename will make sense easily
// if addInNewCommunication is set to true this will appear in communication messages builder
// inputs is the array of inputs required for example button requires Button text and url so it has two inputs
// in some cases simple Input or Select type does not work means they have some complex structure like if you look below
// video gallery so for that instead of mapping with diff text select filepicker we have created seprated for that read about this for better understanding

// some input type requires afterProcessing. what i mean with after processing is for example if you look below Image Gallery there is one input file
// picker is used so once files are dropped we will receive array of files dropped but that is not something server wants so we have to manipulate
// that  instead of array of files this will be array of objects
// {
//   file_data: null,
//   file: el,
//   caption: '',
//   position: 0,
// }
// so this can be Achieved by after processor 

// Need help in adding new module
// First open Card back editor and see already written modules hope your new module will match with any of existing
// just take ref and add new object below and try running it instead of going deep into the code.

export const editorBtnOptionsHeader = [
  {
    id: 'header_1',
    name: 'Fullscreen Components',
    quesMarkText: 'Please note that all full screen components can only contain one component in the builder, no other components can be dragged into the interface as they are full screen app experiences.'
  }
]

export const editorBtnOptions = [
  {
    name: 'Card',
    display: 'Card',
    id: 'card_link_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'CardLinkButtonCardModule',
    addInNewCommunication: true,
    addInQuiz: true,
    addInHomeComponent: true,
    // iconName: 'sim-card',
    builderIcon: svgIcons.BlackCardIcon,
    customIcon: svgIcons.CardLinkDeckIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'CardPicker',
        key: 'redirect_card_id',
        dataType: 'ID',
        title: 'Card',
        value: '',
        placeholder: 'Select Card',
      },
      {
        type: 'FilePicker',
        key: 'icon',
        mutation: {
          key: 'icon_upload',
          dataType: 'Upload',
        },
        dataType: 'String',
        title: 'Button Icon',
        value: '',
        placeholder: 'Enter Button Text',
        multiple: false,
        accept: "image/*"
      },
      {
        type: 'Input',
        key: 'width',
        dataType: 'String',
        title: 'Width of Button (in %)',
        value: '',
        placeholder: 'Enter Button Width',
      },
      {
        type: 'BaseUiTextArea',
        key: 'style',
        dataType: 'String',
        title: 'Custom Css',
        value: '',
        placeholder: '',
      }
    ],
  },
  {
    name: 'Website',
    display: 'Website',
    id: 'button_12',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'ButtonCardModule',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    // iconName: 'link',
    builderIcon: svgIcons.BlackWebsiteIcon,
    customIcon: svgIcons.InsertLinkDeckIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'Input',
        key: 'url',
        dataType: 'String',
        title: 'Button URL',
        value: '',
        placeholder: 'Enter Button URL',
      },
    ],
  },
  {
    name: 'Meeting',
    display: 'Meeting',
    id: 'meeting_link_module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    builderIcon: svgIcons.BlackMeetingIcon,
    __typename: 'MeetingLinkCardModule',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Title',
        value: '',
        placeholder: 'Enter Meeting Title',
      },
      {
        type: 'Input',
        key: 'meeting_link',
        dataType: 'String',
        title: 'Meeting link:',
        value: '',
        placeholder: 'Enter Meeting URL',
      },
    ],
  },
  {
    name: 'Data chip',
    display: 'Data chip',
    id: 'datachip_card_module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_1',
    builderIcon: svgIcons.BlackMeetingIcon,
    customIcon: svgIcons.BlackMeetingIcon,
    addInHomeComponent: true,
    __typename: 'DatachipCardModule',
    inputs: [
      {
        type: 'Input',
        key: 'data_label',
        dataType: 'String',
        title: 'Data Label',
        value: '',
        placeholder: 'Enter Data Label',
      },
      {
        type: 'Input',
        key: 'data_value',
        dataType: 'String',
        title: 'Data Value:',
        value: '',
        placeholder: 'Enter Data Value',
      },
      {
        type: 'Input',
        key: 'size',
        dataType: 'String',
        title: 'Size:',
        value: '',
        placeholder: 'Enter Size',
      },
      {
        type: 'CardPicker',
        key: 'redirect_card_id',
        dataType: 'ID',
        title: 'Card',
        value: '',
        placeholder: 'Select Card',
      },
    ],
  },
  {
    name: 'Announcement',
    display: 'Announcement',
    id: 'announcement_card_module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_1',
    builderIcon: svgIcons.BlackTextBoxIcon,
    customIcon: svgIcons.BlackTextBoxIcon,
    addInHomeComponent: true,
    addInNewCommunication: false,
    __typename: 'AnnouncementCardModule',
    inputs: [
      {
        type: 'Textarea',
        key: 'text',
        dataType: 'String',
        title: 'Alert Text',
        value: '',
        placeholder: 'Enter Alert Text',
      },
      {
        type: 'ColorPicker',
        key: 'text_color',
        dataType: 'String',
        title: 'Text Color',
        value: '',
        placeholder: 'Select Text Color',
      },
      {
        type: 'ColorPicker',
        key: 'background_color',
        dataType: 'String',
        title: 'Background Color',
        value: '',
        placeholder: 'Select Background Color',
      },
    ],
  },
  {
    name: 'Phone',
    display: 'Phone',
    id: 'button_1',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'PhoneButtonCardModule',
    addInNewCommunication: true,
    addInHomeComponent: true,
    // iconName: 'phone',
    builderIcon: svgIcons.BlackPhoneIcon,
    customIcon: svgIcons.PhoneLinkDeckIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'Input',
        key: 'phone_number',
        dataType: 'String',
        title: 'Phone No',
        value: '',
        placeholder: 'Enter Phone No',
      },
    ],
  },
  {
    name: 'Sheets Card (Google & Microsoft)',
    display: 'Sheets Card (Google & Microsoft)',
    id: 'table_card',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_4',
    __typename: 'TableCardModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    // iconName: 'table',
    customIcon: svgIcons.TableDeckIcon,
    builderIcon: svgIcons.BlackGoogleSheetsIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Title',
        value: '',
        placeholder: 'Enter Title Text',
      },
      {
        type: 'Select',
        key: 'google_sheet_integration_id',
        dataType: 'ID',
        title: 'Sheet Integration Id',
        value: null,
        placeholder: 'Select Sheet Integration',
        labelKey: 'name',
        pathToStoreValue: 'appBasicControls.organization.sheets',
        loadOptionsFromState: true,
        options: [],
      },
      {
        type: 'Checkbox',
        key: 'has_card_view',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Table has card view?',
      },
      {
        type: 'Checkbox',
        key: 'has_expanded_view',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Table has expanded view?',
      },
      {
        type: 'Checkbox',
        key: 'freeze_first_column',
        dataType: 'Boolean',
        title: '',
        value: false,
        placeholder: 'Freeze first column of table?',
      },
      {
        type: 'Input',
        key: 'kpi_compare_positive_background_color',
        dataType: 'String',
        title: 'Background color when performance is good',
        value: '',
        placeholder: 'Enter color hex code',
      },
      {
        type: 'Input',
        key: 'kpi_compare_positive_negative_color',
        dataType: 'String',
        title: 'Background color when performance is not good',
        value: '',
        placeholder: 'Enter color hex code',
      },
      {
        type: 'TableColumnPicker',
        key: 'kpi',
        dataType: 'String',
        title: '',
        typeToPick: 'Kpi'
      },
      {
        type: 'TableColumnPicker',
        key: 'dimensions',
        dataType: 'String',
        title: '',
        typeToPick: 'Dimensions'
      },
      {
        type: 'TableColumnPicker',
        key: 'measures',
        dataType: 'String',
        title: '',
        typeToPick: 'Measures'
      },
      {
        type: 'TableColumnPicker',
        key: 'filters',
        dataType: 'String',
        title: '',
        typeToPick: 'Filters'
      },
      {
        type: 'TableColumnPicker',
        key: 'closing',
        dataType: 'String',
        title: '',
        typeToPick: 'Closing'
      },
    ],
  },
  {
    name: 'Single Image',
    display: 'Single Image',
    id: 'button_2',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ImageCardModule',
    addInNewCommunication: true,
    addInQuiz: true,
    addInHomeComponent: true,
    parent: 'option_2',
    // iconName: 'image',
    builderIcon: svgIcons.BlackSingleImageIcon,
    customIcon: svgIcons.ImageDeckIcon,
    inputs: [
      {
        type: 'FilePicker',
        key: 'file_data',
        dataType: 'String',
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
        accept: "image/*"
      },
    ],
  },
  {
    name: 'Image Gallery',
    display: 'Image Gallery',
    id: 'button_3',
    parent: 'option_2',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: true,
    addInQuiz: true,
    __typename: 'ImageGalleryCardModule',
    inputs: [
      {
        type: 'FilePicker',
        key: 'gallery_images',
        subQueryKeys: ['file_data', 'caption', 'position'],
        dataType: '[GalleryMutation]',
        value: [],
        placeholder: 'Drag files here',
        alwaysShowDropZone: true,
        multiple: true,
        accept: "image/*",
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        },
        afterProcessor: d => {
          if (!d) return [];
          return d.map(el => {
            // now if this element has type name in it that means this is not file already saved value
            // so this needs to processing
            if (el.file_data)
              return {
                file_data: el.file_data,
                // file: null,
                caption: el.caption,
                position: el.position,
              };
            if (el.file) return el;
            return {
              file_data: null,
              file: el,
              caption: '',
              position: 0,
            };
          });
        },
      },
      {
        type: 'Select',
        key: 'grid_type',
        title: 'Gallery Type',
        dataType: 'String',
        placeholder: 'Gallery Type',
        options: [
          { label: 'Grid', id: 'Grid' },
          { label: 'Slideshow', id: 'Slideshow' }
        ]
      }
    ],
    builderIcon: svgIcons.BlackImageGalleryIcon
  },
  {
    name: 'Header Image',
    display: 'Header Image',
    id: 'button_header_image',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'HeaderImageCardModule',
    addInNewCommunication: true,
    addInHomeComponent: true,
    parent: 'option_2',
    // iconName: 'image',
    customIcon: svgIcons.ImageDeckIcon,
    builderIcon: svgIcons.BlackHeaderImageIcon,
    inputs: [
      {
        type: 'FilePicker',
        key: 'file_data',
        dataType: 'String',
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
        accept: "image/*"
      },
    ],
  },
  {
    name: 'Single Video',
    display: 'Single Video',
    id: 'button_8',
    icon: <Filter></Filter>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: true,
    addInQuiz: true,
    addInHomeComponent: true,
    __typename: 'YoutubeCardModule',
    parent: 'option_2',
    inputs: [
      {
        type: 'Select',
        key: 'video_type',
        value: '',
        title: 'Video type',
        dataType: 'String',
        placeholder: 'Select video type',
        options: [
          { label: 'Youtube', id: 'Youtube' },
          { label: 'Vimeo', id: 'Vimeo' },
          { label: 'URL', id: 'URL' },
          { label: 'Library', id: 'Library' },
          { label: 'JW Player', id: 'JWPlayer' },
        ],
      },
      {
        type: 'VideoIdOrURLPicker',
        key: 'youtube_video_id',
        title: 'Video URL',
        dataType: 'String',
        value: '',
        placeholder: 'Enter Video URL',
        preProcessor: (value, input, content_module) => {
          switch (content_module.video_type) {
            case 'Vimeo':
            case 'vimeo':
              return `https://vimeo.com/${value}`;
            case 'Youtube':
            case 'youtube':
              return `https://youtu.be/${value}`;
            case 'JWPlayer':
            case 'jwplayer':
              console.log('Preprocessor______________', value);
              return value;
          };
          return value;
        }
      },
      {
        type: 'Input',
        key: 'jw_player_stream_url',
        dataType: 'String',
        title: 'JWPlayer Video URL',
        value: '',
        placeholder: 'Enter URL',
      },
      {
        type: 'Input',
        key: 'jw_player_tags',
        dataType: 'String',
        title: 'JWPlayer Tags',
        value: '',
        placeholder: 'Enter Tags',
      },
      {
        type: 'FilePicker',
        key: 'video_cover_photo_data',
        title: 'Video Cover Photo',
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'video_cover_photo',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'Checkbox',
        key: 'auto_play',
        dataType: 'Boolean',
        title: 'Auto Play',
        value: false,
        placeholder: 'Auto play enabled?',
      },
      {
        type: 'Checkbox',
        key: 'auto_play_in_full_screen',
        dataType: 'Boolean',
        title: 'Auto Play In Full Screen',
        value: false,
        placeholder: 'Is Auto play enabled in full screen mode?',
      },
    ],
    builderIcon: svgIcons.BlackSingleVideoIcon
  },
  {
    name: 'Video Playlist',
    display: 'Video Playlist',
    id: 'button_31',
    parent: 'option_2',
    header: 'header_1',
    icon: <Upload></Upload>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: false,
    __typename: 'VideoGalleryCardModule',
    inputs: [
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Video Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      // {
      //   type: 'FileOrLibraryPicker',
      //   key: 'cover_image_data',
      //   title: 'Image library',
      //   dataType: 'String',
      //   value: '',
      //   placeholder: '',
      //   maxLength: 125
      // },
      {
        type: 'JWPlayerListSelect',
        type: 'Input',
        key: 'title',
        title: 'Playlist Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'BaseUiTextArea',
        key: 'description',
        title: 'Playlist Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'VideoGallery',
        key: 'gallery_videos',
        subQueryKeys: [
          'cover_photo_data',
          'title',
          'video_url',
          'video_type',
          'loop_times',
          'does_loop',
          'position',
          'description',
          'duration'
        ],
        dataType: '[GalleryVideoMutation]',
        value: [],
        placeholder: 'Drag files here',
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      },
      {
        type: 'Checkbox',
        key: 'auto_play',
        dataType: 'Boolean',
        title: 'Auto Play',
        value: false,
        placeholder: 'Auto play enabled?',
      },
      {
        type: 'Select',
        key: 'play_mode',
        title: 'Play Mode',
        dataType: 'String',
        placeholder: 'Play Mode',
        options: [
          { id: 'full_screen', label: 'Full Screen' },
          { id: 'inline', label: 'Inline' }
        ]
      }
    ],
    builderIcon: svgIcons.BlackVideoPlaylistIcon
  },
  {
    name: 'Text',
    display: 'Text',
    id: 'button_4',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'TextCardModule',
    parent: 'option_1',
    addInNewCommunication: true,
    addInDataConnection: true,
    addInQuiz: true,
    addInHomeComponent: true,
    inputs: [
      {
        type: 'Textarea',
        key: 'body',
        dataType: 'String',
        value: '',
        placeholder: 'Write text here!',
      },
    ],
    builderIcon: svgIcons.BlackTextBoxIcon,
  },
  {
    name: 'Single Audio',
    display: 'Single Audio',
    id: 'button_5',
    icon: <Filter></Filter>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    addInNewCommunication: true,
    addInQuiz: true,
    __typename: 'SingleAudioCardModule',
    parent: 'option_2',
    inputs: [
      {
        type: 'Input',
        key: 'title',
        value: '',
        title: 'Title',
        dataType: 'String',
        placeholder: 'Enter audio title',
      },
      {
        type: 'BaseUiTextArea',
        key: 'description',
        value: '',
        title: 'Description',
        dataType: 'String',
        placeholder: 'Enter audio description',
      },
      {
        type: 'FilePicker',
        key: 'cover_photo_data',
        title: 'Cover Photo',
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'FilePicker',
        key: 'file_data',
        title: 'Audio File',
        dataType: 'String',
        multiple: false,
        accept: "audio/*",
        mutation: {
          key: 'file',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
    ],
    builderIcon: svgIcons.BlackSingleAudioIcon
  },
  {
    name: 'Audio Playlist',
    display: 'Audio Playlist',
    id: 'button_7',
    __typename: 'AudioCardModule',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_2',
    header: 'header_1',
    builderIcon: svgIcons.BlackAudioPlaylistIcon,
    inputs: [
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Playlist Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
      },
      {
        type: 'Input',
        key: 'title',
        title: 'Playlist Title',
        dataType: 'String',
        value: '',
        placeholder: 'Write title here!',
        maxLength: 125
      },
      {
        type: 'BaseUiTextArea',
        key: 'description',
        title: 'Playlist Description',
        dataType: 'String',
        value: '',
        placeholder: '',
      },
      {
        type: 'AudioPicker',
        key: 'audios',
        value: [],
        subQueryKeys: [
          'file_data',
          'caption',
          'position',
          'description',
          'duration',
          'cover_photo_data'
        ],
        dataType: '[AudioMutation]',
        placeholder: 'Drag files here',
        multiple: true,
        accept: "audio/*",
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        },
        // afterProcessor: d => {
        //   if (!d) return [];
        //   return d.map(el => {
        //     // now if this element has type name in it that means this is not file already saved value
        //     // so this needs to processing
        //     if (el.file_data)
        //       return {
        //         file_data: el.file_data,
        //         // file: null,
        //         caption: el.caption,
        //         position: el.position,
        //       };
        //     if (el.file) return el;
        //     return {
        //       file_data: null,
        //       file: el,
        //       caption: '',
        //       position: 0,
        //     };
        //   });
        // },
      },
    ],
  },
  {
    name: 'Attachment',
    display: 'Attachment',
    id: 'button_6',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'AttachmentCardModule',
    addInNewCommunication: true,
    addInQuiz: true,
    parent: 'option_2',
    builderIcon: svgIcons.BlackAttachmentIcon,
    inputs: [
      {
        type: 'FilePicker',
        key: 'attachments',
        value: [],
        subQueryKeys: ['file_data', 'text'],
        dataType: '[AttachmentMutation]',
        placeholder: 'Drag files here',
        multiple: true,
        accept: '.pdf,.doc',
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        },
        afterProcessor: d => {
          if (!d) return [];
          return d.map(el => {
            // now if this element has type name in it that means this is not file already saved value
            // so this needs to processing
            if (el.file_data)
              return {
                file_data: el.file_data,
                // file: null,
                text: el.text,
              };
            if (el.file) return el;
            return {
              file_data: null,
              file: el,
              text: '',
            };
          });
        },
      },
    ],
  },
  {
    name: 'CSV Card',
    display: 'CSV Card',
    id: 'button_6',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'CsvCardModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Table Name',
        value: '',
        placeholder: 'Enter Table Name',
      },
      {
        type: 'FilePicker',
        key: 'data',
        value: [],
        multiple: true,
        accept: '.csv',
        dataType: 'Upload',
        mutation: {
          key: 'data',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here',
        multiple: false,
      },
    ],
  },
  {
    name: 'Map Card',
    display: 'Map Card',
    id: 'button_6_123',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'MapCardModule',
    addInNewCommunication: false,
    addInHomeComponent: true,
    addInQuiz: false,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Title',
        value: '',
        placeholder: 'Enter Title',
      },
      {
        type: 'Input',
        key: 'address',
        dataType: 'String',
        title: 'Address',
        value: '',
        placeholder: 'Enter Address',
      },
      {
        type: 'Input',
        key: 'city',
        dataType: 'String',
        title: 'City',
        value: '',
        placeholder: 'Enter City',
      },
      {
        type: 'Input',
        key: 'state',
        dataType: 'String',
        title: 'State',
        value: '',
        placeholder: 'Enter State',
      },
      {
        type: 'Input',
        key: 'zip',
        dataType: 'String',
        title: 'Zip',
        value: '',
        placeholder: 'Enter Zip',
      },
      {
        type: 'Input',
        key: 'latitude',
        dataType: 'String',
        title: 'Latitude',
        value: '',
        placeholder: 'Enter Latitude',
      },
      {
        type: 'Input',
        key: 'longitude',
        dataType: 'String',
        title: 'Longitude',
        value: '',
        placeholder: 'Enter Longitude',
      },
      {
        type: 'Textarea',
        key: 'notes',
        dataType: 'String',
        title: 'Notes',
        value: '',
        placeholder: 'Enter Notes',
      },
    ],
  },
  {
    name: 'Chart Card',
    display: 'Chart Card',
    id: 'button_611',
    icon: <ArrowDown></ArrowDown>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ChartCardModule',
    addInNewCommunication: false,
    addInQuiz: true,
    addInHomeComponent: true,
    parent: 'option_4',
    builderIcon: svgIcons.BlackAttachmentIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Chart Title',
        value: '',
        placeholder: 'Enter Chart Title',
      },
      {
        type: 'Textarea',
        key: 'description',
        dataType: 'String',
        title: 'Chart Description',
        value: '',
        placeholder: 'Enter Chart Description',
      },
      {
        type: 'Select',
        key: 'chart_type',
        title: 'Chart Type',
        dataType: 'String',
        placeholder: 'Chart Type',
        options: [
          {
            "label": "Bar Chart",
            "id": "column_chart_graph"
          },
          {
            "label": "Line Chart",
            "id": "interpolation_graph"
          },
          {
            "label": "Pie Chart",
            "id": "custom_tooltip_graph"
          },
          {
            "label": "Table Chart",
            "id": "table_chart"
          }
        ]
      },
      {
        type: 'BaseUiTextArea',
        key: 'json',
        dataType: 'String',
        title: 'Chart Data',
        value: '',
        placeholder: 'Enter Chart Data',
      },
    ],
  },
  {
    name: 'Embed Code',
    display: 'Embed Code',
    id: 'button_9',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'EmbedCodeCardModule',
    parent: 'option_4',
    builderIcon: svgIcons.BlackEmbedIcon,
    inputs: [
      {
        type: 'BaseUiTextArea',
        key: 'code',
        dataType: 'String',
        title: 'Embed code snippet',
        value: '',
        placeholder: 'Enter Embed code snippet',
      },
      {
        type: 'Input',
        key: 'description',
        dataType: 'String',
        title: 'Embed code description',
        value: '',
        placeholder: 'Enter Embed code description',
      },
    ],
  },
  {
    name: 'Business Card',
    display: 'Business Card',
    id: 'button_10',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'ContactCardModule',
    parent: 'option_4',
    inputs: [
      {
        type: 'Input',
        key: 'personal_first_name',
        dataType: 'String',
        title: 'First Name',
        value: '',
        placeholder: 'Enter First Name'
      },
      {
        type: 'Input',
        key: 'personal_last_name',
        dataType: 'String',
        title: 'Last Name',
        value: '',
        placeholder: 'Enter Last Name'
      },
      {
        type: 'Input',
        key: 'personal_position',
        dataType: 'String',
        title: 'Title/Position',
        value: '',
        placeholder: 'Enter Title/Position'
      },
      {
        type: 'Input',
        key: 'personal_department',
        dataType: 'String',
        title: 'Department',
        value: '',
        placeholder: 'Department'
      },
      {
        type: 'ContactMethod',
        key: 'contact_info_cards',
        dataType: '[ContactInfoCardInput]',
        subQueryKeys: [
          'contact_type',
          'phone_title',
          'phone_type',
          'phone_number',
          'phone_ext',
          'email_address',
          'website_title',
          'website_url',
          'address_title',
          'address_address',
          'address_unit',
          'address_city',
          'address_state',
          'address_zip',
          'position',
          'contact_notes'
        ],
        title: '',
        value: [],
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      }
    ],
    builderIcon: svgIcons.BlackBusinessCardIcon,
  },
  // {
  //   name: 'Meeting',
  //   display: 'Zoom Meeting',
  //   id: 'button_11',
  //   __typename: 'MeetingCardModule',
  //   icon: <Plus></Plus>,
  //   parent: 'option_4',
  //   inputs: [
  //     {
  //       type: 'Select',
  //       key: 'zoom_id',
  //       dataType: 'ID',
  //       title: 'Zoom Account',
  //       value: null,
  //       placeholder: 'Select Zoom Account',
  //       labelKey: 'nickname',
  //       loadOptionsFromState: true,
  //       pathToStoreValue: 'appBasicControls.organization.zooms',
  //       options: [],
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_name',
  //       dataType: 'String',
  //       title: 'Meeting Name',
  //       value: '',
  //       placeholder: 'Enter Meeting Name',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_id',
  //       dataType: 'ID',
  //       title: 'Meeting ID',
  //       value: '',
  //       placeholder: 'Enter Meeting ID',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_passcode',
  //       dataType: 'String',
  //       title: 'Meeting Passcode',
  //       value: '',
  //       placeholder: 'Enter Meeting Passcode',
  //     },
  //     {
  //       type: 'Date',
  //       key: 'meeting_date',
  //       dataType: 'String',
  //       title: 'Meeting Date',
  //       value: '',
  //       placeholder: 'Enter Meeting Date',
  //     },
  //     {
  //       type: 'Time',
  //       key: 'meeting_start_time',
  //       dataType: 'String',
  //       title: 'Meeting Start Time',
  //       value: '',
  //       placeholder: 'Enter Meeting Start Time',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_duration',
  //       dataType: 'String',
  //       title: 'Meeting Duration',
  //       value: '',
  //       placeholder: 'Enter Meeting Duration',
  //     },
  //     {
  //       type: 'Input',
  //       key: 'meeting_notes',
  //       dataType: 'String',
  //       title: 'Meeting Notes',
  //       value: '',
  //       placeholder: 'Enter Meeting Notes',
  //     },
  //   ],
  // },
  // {
  //   name: 'Live Stream',
  //   display: 'Live Stream',
  //   id: 'liveStream',
  //   icon: <ArrowDown></ArrowDown>,
  //   inputs: [
  //     {
  //       type: 'Input',
  //       key: 'title',
  //       title: 'Live Stream Title',
  //       value: '',
  //       placeholder: 'Enter Live Stream title'
  //     },
  //     {
  //       type: 'Input',
  //       key: 'url',
  //       title: 'Live Stream URL',
  //       value: '',
  //       placeholder: 'Enter Live Stream url'
  //     }
  //   ]
  // },
  {
    name: 'Full Screen Web Card',
    display: 'Full Screen Web Card',
    id: 'button_4',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'WebCardModule',
    parent: 'option_4',
    addInNewCommunication: false,
    builderIcon: svgIcons.BlackFullscreenWebCardIcon,
    inputs: [
      {
        type: 'Input',
        key: 'link',
        title: 'Link',
        dataType: 'String',
        value: '',
        placeholder: 'Write link here!',
      },
    ],
  },
  {
    name: 'Salesforce Dashboard',
    display: 'Salesforce Dashboard',
    id: 'button_salesforce',
    icon: <Grab></Grab>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    __typename: 'SalesforceAggregatePointsModule',
    parent: 'option_5',
    addInNewCommunication: false,
    addInHomeComponent: true,
    // point_source_field: ID
    // point_source_title: String
    // card_link_id: ID
    // card_link_title: String,
    // iconName: 'chart-line',
    customIcon: svgIcons.DashboardDeckIcon,
    builderIcon: svgIcons.BlackDashboardIcon,
    inputs: [
      {
        type: 'Input',
        key: 'point_source_title',
        title: 'Point source title',
        dataType: 'String',
        value: '',
        placeholder: 'point source title to display on app',
      },
      {
        type: 'CustomFieldPicker',
        key: 'point_source_field',
        dataType: 'ID',
        title: 'Custom field',
        value: '',
        placeholder: 'Select custom field',
      },
      {
        type: 'Input',
        key: 'card_link_title',
        title: 'Card link title',
        dataType: 'String',
        value: '',
        placeholder: 'Write your card link title here',
      },
      {
        type: 'CardPicker',
        key: 'card_link_id',
        dataType: 'ID',
        title: 'Card',
        value: '',
        placeholder: 'Select Card',
      },
    ],
  },
  {
    name: 'Single Use Button',
    display: 'Single Use Button',
    id: 'button_SingleUseButtonCardModule',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_5',
    __typename: 'SingleUseButtonCardModule',
    addInNewCommunication: true,
    builderIcon: svgIcons.BlackSingleUseButtonIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'Input',
        key: 'url',
        dataType: 'String',
        title: 'Button URL',
        value: '',
        placeholder: 'Enter Button URL',
      },
      {
        type: 'Checkbox',
        key: 'is_selected',
        dataType: 'Boolean',
        title: 'Selection',
        value: false,
        placeholder: 'Is button selected?',
      },
    ],
  },
  {
    name: 'Channel Card',
    display: 'Channel Card',
    id: 'channel_card_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_3',
    __typename: 'ChannelCardModule',
    addInNewCommunication: true,
    addInHomeComponent: true,
    // iconName: 'sim-card',
    builderIcon: svgIcons.BlackCardIcon,
    customIcon: svgIcons.CardLinkDeckIcon,
    inputs: [
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Enter Button Text',
      },
      {
        type: 'ChannelPicker',
        key: 'redirect_channel_id',
        dataType: 'ID',
        title: 'Channel',
        value: '',
        placeholder: 'Select Channel',
      },
    ],
  },
  {
    id: 'share_point_form_module',
    display: 'Share Point Form Module',
    name: 'Share Point Form Module',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_4',
    __typename: 'SharePointFormModule',
    addInHomeComponent: true,
    inputs: [
      {
        type: 'FilePicker',
        key: 'cover_image_data',
        title: 'Form Cover Image',
        showHint: false,
        dataType: 'String',
        multiple: false,
        accept: "image/*",
        mutation: {
          key: 'cover_image',
          dataType: 'Upload',
        },
        value: '',
        placeholder: 'Drag files here to upload...',
      },
      {
        type: 'Input',
        key: 'title',
        dataType: 'String',
        title: 'Form Title',
        value: '',
        placeholder: 'Enter Form Title',
      },
      {
        type: 'Input',
        key: 'description',
        dataType: 'String',
        title: 'Form Description',
        value: '',
        placeholder: 'Enter Form Description',
      },
      {
        type: 'Select',
        key: 'sharepoint_spreadsheet_id',
        dataType: 'ID',
        labelKey: 'name',
        value: null,
        title: 'Sharepoint File',
        value: '',
        placeholder: 'Select SharePoint SpreadSheet',
        pathToStoreValue: 'appBasicControls.organization.sheets',
        loadOptionsFromState: true,
        options: [],
        optionFilter: (options) => {
          return options.filter(el => (el.name && el.name.includes('(Microsoft)')) || (el.name && el.name.includes('(Share Point)')));
        }
      },
      {
        type: 'FormInputList',
        key: 'schema',
        mutation: {
          key: 'schemaArr',
          dataType: '[schemaInput]',
        },
        dataType: 'String',
        value: [],
        placeholder: '',
        preProcessor: d => {
          if (!d) return [];
          return d
            .map(el => ({ ...el }))
            .map((el, i) => {
              delete el.__typename;
              return el;
            });
        }
      },
      // {
      //   type: 'Checkbox',
      //   key: 'is_single_form',
      //   dataType: 'Boolean',
      //   title: 'Single Form',
      //   value: false,
      //   placeholder: 'Is Single Form?',
      // },
      {
        type: 'Select',
        key: 'form_type',
        title: 'Type',
        dataType: 'String',
        placeholder: 'Type',
        options: [
          { id: 'single_form', label: 'Single Form' },
          { id: 'stepped_form', label: 'Stepped Form' }
        ]
      },
      {
        type: 'Checkbox',
        key: 'show_on_home_screen',
        dataType: 'Boolean',
        title: 'Show on Home Screen?',
        value: false,
        placeholder: '',
      },
      {
        type: 'Input',
        key: 'dialog_open_button_text',
        dataType: 'String',
        title: 'Button Text',
        value: '',
        placeholder: 'Open Form',
        visibilityParameter: 'show_on_home_screen',
        isVisible: (value) => {
          return !value
        }
      },
    ]
  },
  {
    name: 'Card Title',
    display: 'Card Title',
    id: 'card_title_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardTitleCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Card Subtitle',
    display: 'Card Subtitle',
    id: 'card_subtitle_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardSubTitleCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Card Tags',
    display: 'Card Tags',
    id: 'card_tags_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardTagsCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  },
  {
    name: 'Card Filters',
    display: 'Card Filters',
    id: 'card_filters_button',
    icon: <Plus></Plus>,
    bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    parent: 'option_6',
    __typename: 'CardFiltersCardModule',
    builderIcon: svgIcons.SwitchAccess,
    customIcon: svgIcons.SwitchAccess,
    inputs: [
      {
        type: 'Info',
        key: 'info',
        dataType: 'String',
      }
    ],
  }
];

export const editorBtnMainOptions = [
  {
    name: 'Quiz',
    display: 'Quiz',
    id: 'quiz',
    addOnlyInQuiz: true,
    icon: (
      <StyledIcon
        src={svgIcons.AdvancedIcon}
      />
    ),
  },
  {
    name: 'Text',
    display: 'Text',
    id: 'option_1',
    icon: (
      <StyledIcon
        src={svgIcons.TextIcon}
      />
      // <svg
      //   width="20"
      //   height="15"
      //   viewBox="0 0 20 15"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <path
      //     d="M0.5 0V3H5.5V15H8.5V3H13.5V0H0.5ZM19.5 5H10.5V8H13.5V15H16.5V8H19.5V5Z"
      //     fill="white"
      //   />
      // </svg>
    ),
  },
  {
    name: 'Media',
    display: 'Media',
    id: 'option_2',
    icon: (
      <StyledIcon
        src={svgIcons.MediaIcon}
      />
    ),
  },
  // {
  //   name: 'Media',
  //   display: 'Media',
  //   id: 'option_3',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="20"
  //       viewBox="0 0 24 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M2 4H0V9H0.01L0 18C0 19.1 0.9 20 2 20H20V18H2V4ZM22 2H14L12 0H6C4.9 0 4.01 0.9 4.01 2L4 14C4 15.1 4.9 16 6 16H22C23.1 16 24 15.1 24 14V4C24 2.9 23.1 2 22 2ZM7 13L11.5 7L15 11.51L17.5 8.5L21 13H7Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Event',
  //   display: 'Event',
  //   id: 'option_4',
  //   icon: (
  //     <svg
  //       width="21"
  //       height="22"
  //       viewBox="0 0 21 22"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M18.5 2H17.5V0H15.5V2H5.5V0H3.5V2H2.5C1.4 2 0.5 2.9 0.5 4V20C0.5 21.1 1.4 22 2.5 22H18.5C19.6 22 20.5 21.1 20.5 20V4C20.5 2.9 19.6 2 18.5 2ZM18.5 20H2.5V7H18.5V20Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: 'Buttons',
    display: 'Buttons',
    id: 'option_3',
    icon: (
      <StyledIcon
        src={svgIcons.ButtonsIcon}
      />
    ),
  },
  // {
  //   name: 'Web',
  //   display: 'Web',
  //   id: 'option_5',
  //   icon: (
  //     <svg
  //       width="24"
  //       height="20"
  //       viewBox="0 0 24 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M2 4H0V9H0.01L0 18C0 19.1 0.9 20 2 20H20V18H2V4ZM22 2H14L12 0H6C4.9 0 4.01 0.9 4.01 2L4 14C4 15.1 4.9 16 6 16H22C23.1 16 24 15.1 24 14V4C24 2.9 23.1 2 22 2ZM7 13L11.5 7L15 11.51L17.5 8.5L21 13H7Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Contact',
  //   display: 'Contact',
  //   id: 'option_6',
  //   icon: (
  //     <svg
  //       width="18"
  //       height="20"
  //       viewBox="0 0 18 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0 2.9 0 4V18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 5C10.66 5 12 6.34 12 8C12 9.66 10.66 11 9 11C7.34 11 6 9.66 6 8C6 6.34 7.34 5 9 5ZM15 17H3V16C3 14 7 12.9 9 12.9C11 12.9 15 14 15 16V17Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  // },
  {
    name: 'Advanced',
    display: 'Advanced',
    id: 'option_4',
    icon: (
      <StyledIcon
        src={svgIcons.AdvancedIcon}
      />
    ),
  },
  {
    name: 'More',
    display: 'More',
    id: 'option_5',
    icon: (
      <StyledIcon
        src={svgIcons.MoreIcon}
      />
    ),
  },
  {
    name: 'Card Meta',
    display: 'Card Meta',
    id: 'option_6',
    icon: (
      <StyledIcon
        src={svgIcons.SwitchAccessWhite}
      />
    ),
  },
];
