import * as React from 'react';
import { ProgressBar, SIZE } from "baseui/progress-bar";

export default () => {
    return <ProgressBar
        value={0}
        infinite
        successValue={100}
        overrides={{
            Bar: {
                style: {
                    margin: '0px !important'
                }
            },
            BarContainer: {
                style: {
                    margin: '0px !important'
                }
            },
            ProgressBar: {
                style: {
                    margin: '0px !important'
                }
            },
            Root: {
                style: {
                    margin: '0px !important'
                }
            },
            InfiniteBar: {
                style: {
                    margin: '0px !important'
                }
            },
            BarProgress: {
                style: {
                    margin: '0px !important'
                }
            }
        }}
    />
}