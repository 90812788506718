import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelMedium,
  LabelSmall,
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import { Spinner } from 'baseui/spinner';
import { DeleteAlt } from "baseui/icon";
import { Input } from 'baseui/input';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const query = (organization_id) => gql`
    query organization_testers {
        organization_testers_by_org(organization_id: "${organization_id}") {
            id
            email
            name
            device_id
          }
    }
`;

const mutation = {
  create: gql`
    mutation createOrganizationTester($name: String, $email: String, $device_id: String, $organization_id: ID!) {
      createOrganizationTester(name: $name, email: $email, device_id: $device_id, organization_id: $organization_id) {
        id
      }
    }
  `,
  update: gql`
    mutation updateOrganizationTester($name: String, $email: String, $device_id: String, $id: ID!) {
      updateOrganizationTester(name: $name, email: $email, device_id: $device_id, id: $id) {
        id
      }
    }
  `,
  delete: gql`
    mutation deleteOrganizationTester($id: ID!) {
      deleteOrganizationTester(id: $id)
    }
  `
};



const Testers = ({ organization }) => {
  const [css, theme] = useStyletron();
  const [testers, setTesters] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [open, setIsOpen] = React.useState(false);


  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [deviceId, setDeviceId] = React.useState('');

  const onClose = () => {
    setIsOpen(false);
    setName('');
    setEmail('');
    setDeviceId('');
  };

  const fetch = (loading = true) => {
    setLoading(true);
    client.query({
      query: query(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setTesters(data.organization_testers_by_org);
      setLoading(false);
    })
  };

  React.useEffect(() => {
    fetch();
  }, []);

  console.log(',,,,,,,,, testers', testers);

  const addNew = () => {
    if (name == '' || email == '' || deviceId == '') return;
    setUpdateLoading(true);
    client.mutate({
      mutation: mutation.create,
      variables: {
        organization_id: organization.id,
        name: name,
        email: email,
        device_id: deviceId,
      }
    }).then(() => {
      fetch(false);
      setUpdateLoading(false);
    })
  };

  const update = (id, name, email, device_id) => {
    setUpdateLoading(true);
    client.mutate({
      mutation: mutation.update,
      variables: {
        id: id,
        name,
        email,
        device_id
      }
    }).then(() => {
      fetch(false);
      setUpdateLoading(false);
    })
  };


  const _delete = (id) => {
    setUpdateLoading(true);
    client.mutate({
      mutation: mutation.delete,
      variables: {
        id: id
      }
    }).then(() => {
      fetch(false);
      setUpdateLoading(false);
    })
  }


  if (loading) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(6).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                {new Array(1).fill('').map(item => <Skeleton
                  width="90%"
                  height="105px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  };
  return (

    <Grid overrides={gridPaddingOverrides}>
      <Cell span={12} align={'right'}>
        <div
          className={css({
            marginRight: '16px',
            position: 'relative',
            float: 'right',
            marginBottom: '16px'
          })}
        >
          <Button
            isLoading={false}
            onClick={() => {
              setIsOpen(true);
            }}
            isSelected
          >
            Add New
          </Button>
        </div>
        {updateLoading && <div
          className={css({
            position: 'relative',
            float: 'right',
            marginRight: '16px', marginTop: '18px'
          })}
        >
          <Spinner size={24} />
        </div>}
      </Cell>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
              // marginTop: '48px',
            }}
          >
            Testers
          </LabelMedium>
          <Cell
            span={12}
            overrides={{
              Cell: { style: { paddingRight: '0px !important' } },
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <LabelSmall>Name</LabelSmall>
              <LabelSmall>Email</LabelSmall>
              <LabelSmall>Device Id</LabelSmall>
              <LabelSmall>Delete</LabelSmall>
            </div>

            {testers.map((t, i) => {
              return <div key={i} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px', height: '36px' }}>
                <div style={{ fontSize: '14px', color: 'black' }}>{t.name}</div>
                <div style={{ fontSize: '14px', color: 'black' }}>{t.email}</div>
                <div style={{ fontSize: '14px', color: 'black' }}>{t.device_id}</div>
                <div style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {
                  _delete(t.id);
                }}>
                  <DeleteAlt size={32} />
                </div>
              </div>
            })}
          </Cell>
        </div>
      </Cell>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={open}
        autoFocus={true}
        focusLock={true}
        onClose={onClose}
      >
        <ModalHeader>{"Add new Tester"}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Name</LabelMedium>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name of tester"
                clearOnEscape
                focus
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Email</LabelMedium>
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email of tester"
                clearOnEscape
                focus
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Device ID</LabelMedium>
              <Input
                value={deviceId}
                onChange={e => setDeviceId(e.target.value)}
                placeholder="Test device Id"
                clearOnEscape
                focus
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              if (name == '' || email == '' || deviceId == '') return;
              addNew();
              onClose();
            }}
          >
            Add
          </ModalButton>
        </ModalFooter>
      </Modal>
    </Grid>
  )
};

export default Testers;