import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Label1, Label2, Label3, Label4 } from 'baseui/typography';
import { useStyletron } from 'baseui';
import Overflow from 'baseui/icon/overflow';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu, NestedMenus } from 'baseui/menu';
import { sourceByType } from '../../util';
import {
  LabelMedium,
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import './attribute.css';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import { getTags, createTag, updateTag } from '../../quries';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { FileUploader } from 'baseui/file-uploader';
import { showAlert } from '../../redux/actions/appBasicControls';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMove } from 'baseui/dnd-list';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, tag) => ({
  userSelect: "none",
  position: 'relative',
  cursor: 'pointer',
  width: '180px',
  minWidth: '180px',
  height: '230px',
  margin: '10px',
  borderRadius: '10px',
  position: 'relative',
  cursor: 'pointer',
  backgroundSize: 'cover',
  backgroundImage: tag.cover_image ? `url('${sourceByType(tag.cover_image, 'full', 'full')}')` : 'linear-gradient(#33339c, #785c61)',
  // background: isDragging ? "white" : "grey",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : "transparent",
  width: '100%',
  display: 'flex',
  overflow: 'auto'
});


const Tags = ({ organization, toaster }) => {
  const [css, theme] = useStyletron();
  const [tags, setTags] = React.useState([]);
  const organization_id = organization.id
  const [popActive, setPopActive] = React.useState({});
  const [isAddNewLoading, setAddNewLoading] = React.useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false);
  const [isLoadingTags, setIsLoadingTags] = React.useState(false);
  const [addOpen, isAddOpen] = React.useState(false);
  const [updateOpen, isUpdateOpen] = React.useState(false);
  const [deleteOpen, isDeleteOpen] = React.useState(false);
  const [tagName, setTagName] = React.useState('')
  const [coverImage, setCoverImage] = React.useState(null)
  const [tagId, setTagId] = React.useState(null)
  const [searchTxt, setSearchTxt] = React.useState('')

  const [newTags, setNewTags] = React.useState([])

  React.useEffect(() => {
    makeNewtags()
  }, [tags])

  const makeNewtags = () => {
    const columnCounts = 8;
    let newTags = []
    let temp = [];
    const sortTags = [...tags].sort((a, b) => a.position - b.position)
    sortTags.map((tag, index) => {
      temp.push({ ...tag, index: index });
      if ((index + 1) % columnCounts === 0) {
        newTags.push(temp)
        temp = [];
      }
    });
    if (temp.length > 0) newTags.push(temp);
    setNewTags(newTags)
  }

  const loadTags = (loading = false) => {
    if (!loading) {
      setIsLoadingTags(true);
    }
    client.query({
      query: getTags(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setIsLoadingTags(false);
      setIsUpdateLoading(false);
      setAddNewLoading(false);

      setTags([...data.tags_org].sort((a, b) => a.position - b.position));
    })
  };

  const onClose = () => {
    isAddOpen(false);
    isUpdateOpen(false)
    isDeleteOpen(false)
    setCoverImage(null)
    setTagName('')
    setTagId('')
    tags.filter(item => searchTxt ? item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1 : true).map((item, index) => setPopActive({ ...popActive, [index]: true }))
  }

  const showMessage = () => {
    // dispatch(
    //   showAlert({
    //     msg: 'The tag changes have been saved.',
    //     error: false,
    //   })
    // );
  }

  const onSave = async () => {
    setIsUpdateLoading(true);
    await client.mutate({
      mutation: updateTag,
      variables: {
        id: tagId,
        name: tagName,
        cover_image: Array.isArray(coverImage) ? coverImage : null,
        organization_id: organization_id,
        // let toastKey = toaster.info('Saving tags...');
        // await Promise.all(tags.filter(e => {
        //   return e.id == e.name ? e.isDeleted ? false : true : true
        // }).map((el) => {
        //   if (el.id == el.name) {
        //     el.id = null
      }
    })
    loadTags(false);
    onClose();
    showMessage();
  };

  const onReorder = async (items) => {
    setIsUpdateLoading(true);
    await Promise.all(items.map(async item => {
      const { id, position } = item;
      await client.mutate({
        mutation: updateTag,
        variables: {
          id,
          position,
          organization_id: organization_id,
        }
      })
    }));
    // toaster.clear(toastKey);
    loadTags();
    onClose();
    showMessage();
  }

  const onDelete = async () => {
    setIsUpdateLoading(true);
    await client.mutate({
      mutation: updateTag,
      variables: {
        id: tagId,
        organization_id: organization_id,
        isDeleted: true
      }
    })
    loadTags();
    onClose();
    showMessage();
  };

  const onAdd = async () => {
    setAddNewLoading(true);
    await client.mutate({
      mutation: createTag,
      variables: {
        name: tagName,
        organization_id: organization_id,
        cover_image: coverImage,
      }
    })
    loadTags();
    onClose();
    showMessage();
  }

  React.useEffect(() => {
    loadTags();
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source, ...rest }) => {
    if (!destination) {
      return;
    }

    let newArr = reorder(
      [...tags],
      source.index,
      destination.index
    );
    newArr = newArr.map((el, i) => {
      return {
        ...el,
        position: i
      }
    });
    setTags(newArr)
    onReorder(newArr)
  }

  if (isLoadingTags) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(6).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                {new Array(1).fill('').map(item => <Skeleton
                  width="90%"
                  height="105px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }



  const renderTagItem = (provided, snapshot, index, tag) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style,
          tag
        )}
      >
        <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.1)', borderRadius: '10px' }}></div>
        <Label3 style={{ position: 'absolute', left: '10px', bottom: '10px', margin: 0, color: 'white' }}>{tag.name + '___' + tag.position}</Label3>
        <div className={css({
          position: 'absolute',
          width: theme.sizing.scale1200,
          height: theme.sizing.scale1200,
          right: theme.sizing.scale300,
          top: theme.sizing.scale0,
        })}
        >
          <StatefulPopover
            initialState={{ isOpen: false }}
            dismissOnEsc={true}
            dismissOnClickOutside={true}
            overrides={{
              Body: {
                style: {
                  zIndex: 3
                }
              }
            }}
            content={() => {
              if (popActive[index]) return null
              return <NestedMenus>
                <StatefulMenu
                  items={[
                    { label: 'Update' },
                    { label: 'Delete' },
                  ]}
                  overrides={{
                    List: {
                      style: {
                        width: '120px',
                        maxHeight: '1000px',
                        overflow: 'auto',
                      },
                    },
                  }}
                  onItemSelect={({ item, event }) => {
                    setPopActive({ ...popActive, [index]: true })
                    if (item.label === 'Update') {
                      setTagId(tag.id)
                      setTagName(tag.name)
                      setCoverImage(tag.cover_image)
                      isUpdateOpen(true)
                    }
                    if (item.label === 'Delete') {
                      setTagId(tag.id)
                      isDeleteOpen(true)
                    };
                  }}
                />
              </NestedMenus>
            }}
            placement={PLACEMENT.top}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation()
              setPopActive({ ...popActive, [index]: false })
            }}
          >
            <Overflow
              className={css({
                top: '-10px',
                right: '-7px',
                color: 'white !important',
                position: 'absolute',
                cursor: 'pointer',
                marginBottom: '0px',
              })}
              size={36}
            />
          </StatefulPopover>
        </div>
      </div>
    )
  }

  const renderUpdateModal = () => {
    return (
      <Modal
        onClose={onClose}
        closeable
        isOpen={addOpen || updateOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>{updateOpen ? 'Update Tag' : 'Add Tag'}</ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <Grid overrides={{
            Grid: {
              style: () => ({
                padding: '0px !important',
              }),
            },
          }}>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                  }),
                },
              }}
            >
              <Label2 style={{ marginBottom: '0.5rem' }}>Name</Label2>
              <Input
                value={tagName}
                onChange={e => setTagName(e.target.value)}
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                    marginTop: '1rem'
                  }),
                },
              }}
            >
              <Label2 style={{ marginBottom: '0.5rem' }}>Cover Image</Label2>
              {coverImage && <img src={Array.isArray(coverImage) ? URL.createObjectURL(coverImage[0]) : sourceByType(coverImage, 'full', 'full')} style={{ width: '100%', margin: 'auto' }}></img>}
              <FileUploader
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setCoverImage(acceptedFiles);
                }}
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={onClose}>
            Cancel
          </ModalButton>
          {addOpen && <ModalButton onClick={() => onAdd()} isLoading={isAddNewLoading}>Save</ModalButton>}
          {updateOpen && <ModalButton onClick={() => onSave()} isLoading={isUpdateLoading}>Update</ModalButton>}
        </ModalFooter>
      </Modal>
    )
  }

  const renderDeleteModal = () => {
    return (
      <Modal
        onClose={onClose}
        closeable
        isOpen={deleteOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this tag?
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton onClick={onDelete} isLoading={isUpdateLoading}>Delete</ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div
          className={css({
            marginBottom: '24px',
            marginTop: '1rem',
          })}
        >
          <div style={{ marginLeft: '10px' }}>
            <Input
              startEnhancer={<Search
                className={css({
                  color: 'black !important',
                  cursor: 'pointer',
                })}
                size={20} />}
              value={searchTxt}
              onChange={e => setSearchTxt(e.target.value)}
              clearOnEscape
              placeholder='Search Tags'
            />
          </div>
          <Cell overrides={cellPaddingOverrides} span={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <LabelMedium style={{ marginLeft: '10px' }}>Browse Tags</LabelMedium>
              <Button isSelected onClick={() => { isAddOpen(true); }}>Add</Button>
            </div>
          </Cell>
        </div>
      </Cell>
      <DragDropContext onDragEnd={onDragEnd}>
        {newTags.map((sideTags, rowIndex) => (
          <Droppable droppableId={'droppable' + rowIndex} direction='horizontal' key={'row' + rowIndex}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {sideTags.filter(item => searchTxt ? item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1 : true).map(({ index, ...tag }, columnIndex) => (
                  <Draggable key={tag.id} draggableId={tag.id} index={index} isDragDisabled={!!searchTxt}>
                    {(provided, snapshot) => {
                      return renderTagItem(provided, snapshot, index, tag)
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>

      {searchTxt && tags.filter(item => searchTxt ? item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1 : true).length == 0 && (
        <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}><Label2>No data found</Label2></div>
      )}

      {renderUpdateModal()}
      {renderDeleteModal()}
    </Grid>
  );
};

export default Tags;
