import gql from 'graphql-tag';
const INTEGRATIONS_OPTIONS = (organization_id) => [
  {
    type: 'Zoom',
    display: 'Zoom',
    fields: [
      {
        key: 'nickname',
        display: 'Nickname',
        isPasswordType: false
      },
      {
        key: 'app_key',
        display: 'App Key',
        isPasswordType: false
      },
      {
        key: 'app_secret',
        display: 'App Secret',
        isPasswordType: true
      },
      {
        key: 'jwt_app_key',
        display: 'JWT App Key',
        isPasswordType: false
      },
      {
        key: 'jwt_app_secret',
        display: 'JWT App Secret',
        isPasswordType: true
      }
    ],
    query: gql`
      query zooms {
          zooms_org(
          organization_id: "${organization_id}"
          ) {
          nickname
          app_key
          app_secret
          jwt_app_key
          jwt_app_secret
          id
          }
      }
      `
    ,
    mutations: {
      create: gql`
          mutation createZoom(
          $nickname: String
          $app_key: String
          $app_secret: String
          $jwt_app_key: String
          $jwt_app_secret: String
          $organization_id: ID
          ) {
          createZoom(
              nickname: $nickname
              app_key: $app_key
              app_secret: $app_secret
              jwt_app_key: $jwt_app_key
              jwt_app_secret: $jwt_app_secret
              organization_id: $organization_id
          ) {
              nickname
              app_key
              app_secret
              jwt_app_key
              jwt_app_secret
              id
          }
          }
      `
      ,
      update: gql`
                mutation updateZoom(
                    $nickname: String
                    $app_key: String
                    $app_secret: String
                    $jwt_app_key: String
                    $jwt_app_secret: String
                    $id: ID!
                ) {
                    updateZoom(
                    nickname: $nickname
                    app_key: $app_key
                    app_secret: $app_secret
                    jwt_app_key: $jwt_app_key
                    jwt_app_secret: $jwt_app_secret
                    id: $id
                    ) {
                    nickname
                    app_key
                    app_secret
                    jwt_app_key
                    jwt_app_secret
                    id
                    }
                }
                `
      ,
      delete: gql`
                  mutation deleteZoom(
                    $id: ID!
                  ) {
                    deleteZoom(
                      id: $id
                    )
                  }
                `
    }
  },
  {
    type: 'Mux',
    display: 'Mux',
    fields: [
      {
        key: 'access_token_name',
        display: 'Access Token Name',
        isPasswordType: false
      },
      {
        key: 'environment_type',
        display: 'Environment Type',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return [
            {
              id: 'Development',
              name: 'Development'
            },
            {
              id: 'Production',
              name: 'Production'
            },
          ]
        }
      },
      {
        key: 'access_token_id',
        display: 'Access Token ID',
        isPasswordType: true
      },
      {
        key: 'access_token_secret',
        display: 'Access Token Secret ',
        isPasswordType: true
      }
    ],
    query: gql`
      query muxs {
          muxs_org(
            organization_id: "${organization_id}"
          ) {
            access_token_name
            environment_type
            access_token_id
            access_token_secret
            id
          }
      }
      `
    ,
    mutations: {
      create: gql`
        mutation createMux(
          $access_token_name: String
          $environment_type: String
          $access_token_id: String
          $access_token_secret: String
          $organization_id: ID
        ) {
          createMux(
            access_token_name: $access_token_name
            environment_type: $environment_type
            access_token_id: $access_token_id
            access_token_secret: $access_token_secret
            organization_id: $organization_id
          ) {
            access_token_name
            environment_type
            access_token_id
            access_token_secret
            id
          }
        }
      `
      ,
      update: gql`
        mutation updateMux(
          $access_token_name: String
          $environment_type: String
          $access_token_id: String
          $access_token_secret: String
          $id: ID!
        ) {
          updateMux(
            access_token_name: $access_token_name
            environment_type: $environment_type
            access_token_id: $access_token_id
            access_token_secret: $access_token_secret
            id: $id
          ) {
            access_token_name
            environment_type
            access_token_id
            access_token_secret
            id
          }
        }
      `,
      delete: gql`
        mutation deleteMux(
          $id: ID!
        ) {
          deleteMux(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'CustomCrm',
    display: 'Custom CRM',
    fields: [
      {
        key: 'api_url',
        display: 'API URL',
        isPasswordType: false
      },
      {
        key: 'api_key',
        display: 'API Key',
        isPasswordType: true
      },
      {
        key: 'api_path',
        display: 'API Path',
        isPasswordType: false
      }
    ],
    query: gql`
      query custom_crms {
          custom_crms_org(
            organization_id: "${organization_id}"
          ) {
            api_url
            api_key
            api_path
            id
          }
      }
      `
    ,
    mutations: {
      create: gql`
        mutation createCustomCrm(
          $api_url: String
          $api_key: String
          $api_path: String
          $organization_id: ID
        ) {
          createCustomCrm(
            api_url: $api_url
            api_key: $api_key
            api_path: $api_path
            organization_id: $organization_id
          ) {
            api_url
            api_key
            api_path
            id
          }
        }
      `
      ,
      update: gql`
        mutation updateCustomCrm(
          $api_url: String
          $api_key: String
          $api_path: String
          $id: ID!
        ) {
          updateCustomCrm(
            api_url: $api_url
            api_key: $api_key
            api_path: $api_path
            id: $id
          ) {
            api_url
            api_key
            api_path
            id
          }
        }
      `,
      delete: gql`
        mutation deleteCustomCrm(
          $id: ID!
        ) {
          deleteCustomCrm(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'Shopify',
    display: 'Shopify',
    fields: [
      {
        key: 'url',
        display: 'Shopify URL',
        isPasswordType: false
      },
      {
        key: 'key',
        display: 'Shopify Key',
        isPasswordType: true
      },
      {
        key: 'version',
        display: 'Shopify Version Number',
        isPasswordType: false
      }
    ],
    query: gql`
      query shopifies {
        shopifies_org(
            organization_id: "${organization_id}"
          ) {
            url
            key
            version
            id
          }
      }
      `
    ,
    mutations: {
      create: gql`
        mutation createShopify(
          $url: String
          $key: String
          $version: String
          $organization_id: ID
        ) {
          createShopify(
            url: $url
            key: $key
            version: $version
            organization_id: $organization_id
          ) {
            url
            key
            version
            id
          }
        }
      `
      ,
      update: gql`
        mutation updateShopify(
          $url: String
          $key: String
          $version: String
          $id: ID!
        ) {
          updateShopify(
            url: $url
            key: $key
            version: $version
            id: $id
          ) {
            url
            key
            version
            id
          }
        }
      `,
      delete: gql`
        mutation deleteShopify(
          $id: ID!
        ) {
          deleteShopify(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'JWPlayer',
    display: 'JWPlayer',
    fields: [
      {
        key: 'api_version',
        display: 'API version',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return [
            {
              id: 'v1',
              name: 'v1 API Credential'
            },
            {
              id: 'v2',
              name: 'v2 API Credential'
            },
          ]
        }
      },
      {
        key: 'app_key',
        display: 'App key', // Property ID
        isPasswordType: true
      },
      {
        key: 'app_secret',
        display: 'App secret',
        isPasswordType: true
      },
      {
        key: 'android_sdk_key',
        display: 'Android SDK key',
        isPasswordType: true
      },
      {
        key: 'ios_sdk_key',
        display: 'iOS SDK key',
        isPasswordType: true
      },
    ],
    query: gql`
      query jwplayers {
          jwplayers_org(
            organization_id: "${organization_id}"
          ) {
            api_version
            app_key
            app_secret
            android_sdk_key
            ios_sdk_key
            id
          }
      }
      `
    ,
    mutations: {
      create: gql`
        mutation createJWPlayer(
          $api_version: String
          $app_key: String
          $app_secret: String
          $android_sdk_key: String
          $ios_sdk_key: String
          $organization_id: ID
        ) {
          createJWPlayer(
            api_version: $api_version
            app_key: $app_key
            app_secret: $app_secret
            android_sdk_key: $android_sdk_key
            ios_sdk_key: $ios_sdk_key
            organization_id: $organization_id
          ) {
            api_version
            app_key
            app_secret
            organization_id
            android_sdk_key
            ios_sdk_key
            id
          }
        }
      `
      ,
      update: gql`
        mutation updateJWPlayer(
          $id: ID!
          $api_version: String
          $app_key: String
          $app_secret: String
          $android_sdk_key: String
          $ios_sdk_key: String
        ) {
          updateJWPlayer(
            id: $id
            api_version: $api_version
            app_key: $app_key
            app_secret: $app_secret
            android_sdk_key: $android_sdk_key
            ios_sdk_key: $ios_sdk_key
          ) {
            api_version
            app_secret
            app_key
            android_sdk_key
            ios_sdk_key
            id
          }
        }
      `,
      delete: gql`
        mutation deleteJWPlayer(
          $id: ID!
        ) {
          deleteJWPlayer(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'Auth0',
    display: 'Auth0',
    fields: [
      {
        key: 'client_id',
        display: 'Client Id',
        isPasswordType: false
      },
      {
        key: 'domain',
        display: 'Domain',
        isPasswordType: false
      },
      {
        key: 'client_secret',
        display: 'Client Secret',
        isPasswordType: true
      }
    ],
    query: gql`
            query auth0s {
            auth0s_org(
                organization_id: "${organization_id}"
                ) {
                domain
                client_id
                client_secret
                id
            }
            }
            `
    ,
    mutations: {
      create: gql`
                mutation createAuth0(
                $domain: String
                $client_id: String
                $client_secret: String
                $organization_id: ID
                ) {
                createAuth0(
                    domain: $domain
                    client_id: $client_id
                    client_secret: $client_secret
                    organization_id: $organization_id
                ) {
                    domain
                    client_id
                    client_secret
                    id
                }
                }
          `,
      update: gql`
                mutation updateAuth0(
                    $domain: String
                    $client_id: String
                    $client_secret: String
                    $id: ID!
                ) {
                    updateAuth0(
                    domain: $domain
                    client_id: $client_id
                client_secret: $client_secret
                id: $id
                ) {
                domain
                client_id
                client_secret
                id
                }
            }
        `,
      delete: gql`
        mutation deleteAuth0(
          $id: ID!
        ) {
          deleteAuth0(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationFirebase',
    display: 'Firebase',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false
      },
      {
        key: 'auth_key',
        display: 'Auth Key',
        isPasswordType: false,
        help: {
          url: "https://knowledge.ably.com/where-can-i-find-my-google/firebase-cloud-messaging-api-key",
          title: "How to get key?"
        }
      }
    ],
    query: gql`
        query organization_firebase {
          organization_firebase_org(
            organization_id: "${organization_id}"
            ) {
            auth_key
            name
            id
          }
        }
        `,
    mutations: {
      create: gql`
            mutation createFirebase(
              $name: String
              $auth_key: String
              $organization_id: ID
            ) {
              createFirebase(
                name: $name
                auth_key: $auth_key
                organization_id: $organization_id
              ) {
                name
                auth_key
                organization_id
                id
              }
            }
          `,
      update: gql`
          mutation updateFirebase(
            $name: String
            $auth_key: String
            $id: ID!
          ) {
            updateFirebase(
              name: $name
              auth_key: $auth_key
              id: $id
            ) {
              name
              auth_key
              organization_id
              id
            }
          }
        `,
      delete: gql`
          mutation deleteFirebase(
            $id: ID!
          ) {
            deleteFirebase(
              id: $id
            )
          }
        `
    }
  },
  {
    type: 'AnalyticIntegration',
    display: 'Analytics',
    fields: [{
      key: 'schema_name',
      display: 'Schema Name',
      isPasswordType: false
    }],
    query: gql`
        query analytic_integrations {
          analytic_integrations_org(
            organization_id: "${organization_id}"
            ) {
            schema_name
            id
          }
        }
        `,
    mutations: {
      create: gql`
                mutation createAnalyticIntegration(
                $schema_name: String
                $organization_id: ID
                ) {
                createAnalyticIntegration(
                    schema_name: $schema_name
                    organization_id: $organization_id
                ) {
                    schema_name
                    id
                }
                }
            `
      ,
      update: gql`
                mutation updateAnalyticIntegration(
                    $schema_name: String
                    $id: ID!
                ) {
                    updateAnalyticIntegration(
                    schema_name: $schema_name
                    id: $id
                    ) {
                    schema_name
                    id
                    }
                }
                `,
      delete: gql`
                mutation deleteAnalyticIntegration(
                  $id: ID!
                ) {
                  deleteAnalyticIntegration(
                    id: $id
                  )
                }
              `
    }
  },
  {
    type: 'SalesforceIntegration',
    display: 'Salesforce',
    fields: [
      {
        key: 'username',
        display: 'Username',
        isPasswordType: false
      },
      {
        key: 'password',
        display: 'Password',
        isPasswordType: true
      },
      {
        key: 'url',
        display: 'URL',
        isPasswordType: false
      },
    ],
    query: gql`
      query salesforces {
          salesforce_integrations_org(
          organization_id: "${organization_id}"
          ) {
            id
            username
            password
            url
          }
      }
      `
    ,
    mutations: {
      create: gql`
          mutation createSalesforceIntegration(
          $username: String
          $password: String
          $url: String
          $organization_id: ID
          ) {
            createSalesforceIntegration(
              username: $username
              password: $password
              url: $url
              organization_id: $organization_id
          ) {
            id
            username
            password
            url
          }
          }
      `
      ,
      update: gql`
      mutation updateSalesforceIntegration(
        $username: String
        $password: String
        $url: String
        $id: ID!
        ) {
          updateSalesforceIntegration(
            username: $username
            password: $password
            url: $url
            id: $id
        ) {
          id
          username
          password
          url
        }
        }
    `,
      delete: gql`
    mutation deleteSalesforceIntegration(
      $id: ID!
    ) {
      deleteSalesforceIntegration(
        id: $id
      )
    }
  `
    }
  },
  {
    type: 'CustomField',
    display: 'Custom Fields',
    selectDropDownDisplay: 'Custom Fields(Salesforce)',
    fields: [
      {
        key: 'integration_type',
        display: 'Integration Type',
        isPasswordType: false,
        value: 'Salesforce',
        disabled: true
      },
      {
        key: 'field_name',
        display: 'Field Name',
        isPasswordType: false
      }
    ],
    query: gql`
      query custom_fields {
        custom_field_by_org (
          organization_id: "${organization_id}"
          integration_type: "Salesforce"
        ) {
          id
          integration_type
          field_name
        }
      }
    `,
    mutations: {
      create: gql`
        mutation createCustomField(
          $integration_type: String
          $field_name: String
          $organization_id: ID
        ) {
          createCustomField(
            integration_type: $integration_type
            field_name: $field_name
            organization_id: $organization_id
          ) {
            id
          }
        }
      `,
      update: gql`
        mutation updateCustomField(
          $id: ID!
          $integration_type: String
          $field_name: String
        ) {
          updateCustomField(
            id: $id
            integration_type: $integration_type
            field_name: $field_name
          ) {
            id
          }
        }
      `,
      delete: gql`
      mutation deleteCustomField(
        $id: ID!
      ) {
        deleteCustomField(
          id: $id
        )
      }
    `

    }
  },
  {
    type: 'CustomField',
    display: 'Custom Fields',
    selectDropDownDisplay: 'Custom Fields(Basic)',
    fields: [
      {
        key: 'integration_type',
        display: 'Integration Type',
        isPasswordType: false,
        value: 'Basic',
        disabled: true
      },
      {
        key: 'field_name',
        display: 'Field Name',
        isPasswordType: false
      }
    ],
    query: gql`
      query custom_fields {
        custom_field_by_org (
          organization_id: "${organization_id}"
          integration_type: "Basic"
        ) {
          id
          integration_type
          field_name
        }
      }
    `,
    mutations: {
      create: gql`
        mutation createCustomField(
          $integration_type: String
          $field_name: String
          $organization_id: ID
        ) {
          createCustomField(
            integration_type: $integration_type
            field_name: $field_name
            organization_id: $organization_id
          ) {
            id
          }
        }
      `,
      update: gql`
        mutation updateCustomField(
          $id: ID!
          $integration_type: String
          $field_name: String
        ) {
          updateCustomField(
            id: $id
            integration_type: $integration_type
            field_name: $field_name
          ) {
            id
          }
        }
      `,
      delete: gql`
      mutation deleteCustomField(
        $id: ID!
      ) {
        deleteCustomField(
          id: $id
        )
      }
    `
    }
  },
  {
    type: 'OrganizationSegment',
    display: 'Segment',
    fields: [
      {
        key: 'key',
        display: 'Key from Segment',
        isPasswordType: false
      }
    ],
    query: gql`
    query organization_segments {
      organization_segments_by_org (
        organization_id: "${organization_id}"
      ) {
        id
        key
      }
    }
  `,
    mutations: {
      create: gql`
        mutation createOrganizationSegment(
          $key: String
          $organization_id: ID
        ) {
          createOrganizationSegment(
            key: $key
            organization_id: $organization_id
          ) {
            id
          }
        }
      `,
      update: gql`
        mutation updateOrganizationSegment(
          $id: ID!
          $key: String
        ) {
          updateOrganizationSegment(
            id: $id
            key: $key
          ) {
            id
          }
        }
      `,
      delete: gql`
      mutation deleteOrganizationSegment(
        $id: ID!
      ) {
        deleteOrganizationSegment(
          id: $id
        )
      }
    `
    }
  },
  {
    type: 'OrganizationShake',
    display: 'Shake',
    fields: [
      {
        key: 'client_id',
        display: 'Client Id',
        isPasswordType: false
      },
      {
        key: 'client_secret',
        display: 'Client Secret',
        isPasswordType: true
      }
    ],
    query: gql`
            query organization_shakes {
              organization_shakes_by_org(
                organization_id: "${organization_id}"
                ) {
                client_id
                client_secret
                id
            }
            }
            `
    ,
    mutations: {
      create: gql`
                mutation createOrganizationShake(
                $client_id: String
                $client_secret: String
                $organization_id: ID
                ) {
                  createOrganizationShake(
                    client_id: $client_id
                    client_secret: $client_secret
                    organization_id: $organization_id
                ) {
                    client_id
                    client_secret
                    id
                }
                }
          `,
      update: gql`
                mutation updateOrganizationShake(
                    $client_id: String
                    $client_secret: String
                    $id: ID!
                ) {
                  updateOrganizationShake(
                    client_id: $client_id
                    client_secret: $client_secret
                    id: $id
                    ) {
                      client_id
                      client_secret
                      id
                  }
            }
        `,
      delete: gql`
        mutation deleteOrganizationShake(
          $id: ID!
        ) {
          deleteOrganizationShake(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationGoogleSheets',
    display: 'Google Sheet',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false
      },
      {
        key: 'auth_key',
        display: 'Authentication Key',
        isPasswordType: false,
        help: {
          url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
          title: "How to get API Key?"
        }
      },
      {
        key: 'spreadsheet_id',
        display: 'Spreadsheet Id',
        isPasswordType: false
      },
      {
        key: 'sheet_name',
        display: 'Sheet Name',
        isPasswordType: false
      },
      {
        key: 'sync_in_realtime',
        display: 'Sync in real time',
        type: 'toggle',
      },
      {
        key: 'sync_frequency',
        display: 'Sync frequency(Cron Expression)',
        description: 'Cron expression will use to set frequency of sync and works only when sync in real time is off.',
        isPasswordType: false,
        hasHr: true,
        help: {
          url: "https://crontab.cronhub.io/",
          title: "Generate your cron expression here"
        }
      },
      {
        key: 'organization_mongo_id',
        display: 'Mongo Integration',
        description: 'Select which mongo integration to use with this integration',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return integrations.filter(el => el.__typename == "OrganizationMongo")
        }
      }
    ],
    query: gql`
            query organization_google_sheet_org {
              organization_google_sheet_org(
                organization_id: "${organization_id}"
                ) {
                  id
                  organization_id
                  auth_key
                  sheet_name
                  sync_in_realtime
                  sync_frequency
                  created_at
                  updated_at
                  organization_mongo_id
                  name
                  spreadsheet_id
            }
            }
            `
    ,
    mutations: {
      create: gql`
                mutation createGoogleSheet(
                $organization_id: ID
                $auth_key: String
                $sheet_name: String
                $sync_in_realtime: Boolean
                $sync_frequency: String
                $name: String
                $organization_mongo_id: ID
                $spreadsheet_id: String
                ) {
                  createGoogleSheet(
                    organization_id: $organization_id
                    auth_key:$auth_key
                    sheet_name:$sheet_name
                    sync_in_realtime:$sync_in_realtime
                    sync_frequency:$sync_frequency
                    organization_mongo_id: $organization_mongo_id
                    name:$name
                    spreadsheet_id: $spreadsheet_id
                ) {
                  organization_id
                  auth_key
                  sheet_name
                  sync_in_realtime
                  sync_frequency
                  organization_mongo_id
                  name
                  spreadsheet_id
                }
                }
          `,
      update: gql`
      mutation updateGoogleSheet(
        $id: ID!
        $auth_key: String
        $sheet_name: String
        $sync_in_realtime: Boolean
        $sync_frequency: String
        $name: String
        $organization_mongo_id: ID
        $spreadsheet_id: String
        ) {
          updateGoogleSheet(
            id: $id
            auth_key:$auth_key
            sheet_name:$sheet_name
            sync_in_realtime:$sync_in_realtime
            sync_frequency:$sync_frequency
            organization_mongo_id: $organization_mongo_id
            name:$name
            spreadsheet_id: $spreadsheet_id
        ) {
          id
          auth_key
          sheet_name
          sync_in_realtime
          sync_frequency
          name
          organization_mongo_id
          spreadsheet_id
        }
        }
        `,
      delete: gql`
        mutation deleteGoogleSheet(
          $id: ID!
        ) {
          deleteGoogleSheet(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationMongo',
    display: 'Mongo',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false
      },
      {
        key: 'uri',
        display: 'MongoDB Connection String',
        isPasswordType: false
      }
    ],
    query: gql`
            query organization_mongos {
              organization_mongos_by_org(
                organization_id: "${organization_id}"
                ) {
                uri
                id
                name
            }
            }
            `
    ,
    mutations: {
      create: gql`
                mutation createOrganizationMongo(
                $uri: String
                $name: String
                $organization_id: ID
                ) {
                  createOrganizationMongo(
                    uri: $uri
                    name: $name
                    organization_id: $organization_id
                ) {
                    uri
                    id
                }
                }
          `,
      update: gql`
                mutation updateOrganizationMongo(
                    $uri: String
                    $name: String
                    $id: ID!
                ) {
                  updateOrganizationMongo(
                    uri: $uri
                    name: $name
                    id: $id
                  ) {
                      uri                      
                      id
                  }
            }
        `,
      delete: gql`
        mutation deleteOrganizationMongo(
          $id: ID!
        ) {
          deleteOrganizationMongo(
            id: $id
          )
        }
      `
    }
  },
  //   {
  //     type: 'CustomApi',
  //     display: 'Custom API',
  //     fields: [
  //       {
  //         key: 'name',
  //         display: 'Name',
  //         isPasswordType: false
  //       },
  //       {
  //         key: 'base_url',
  //         display: 'Base URL',
  //         isPasswordType: false,
  //         initValue: 'https://'
  //       },
  //       {
  //         key: 'auth_method',
  //         display: 'Authentication Method',
  //         description: 'Select authentication method for this API',
  //         isPasswordType: false,
  //         type: 'select',
  //         hasHr: true,
  //         valueKey: 'id',
  //         labelKey: 'name',
  //         getOptions: (integrations) => {
  //           return [
  //             {
  //               id: 'no_auth',
  //               name: 'No Auth'
  //             },
  //             {
  //               id: 'basic_auth',
  //               name: 'Basic Auth'
  //             },
  //             {
  //               id: 'token_auth',
  //               name: 'Token Auth'
  //             }
  //           ]
  //         }
  //       },
  //       {
  //         key: 'auth_information',
  //         display: 'Auth Information',
  //         isPasswordType: false
  //       },
  //       {
  //         key: 'api_endpoints',
  //         display: 'API Endpoints',
  //         description: 'Please enter API endpoints',
  //         isPasswordType: false,
  //         type: 'keyvalue',
  //         hasHr: true,
  //         vKey: 'endpoint',
  //         nKey: 'name',
  //         nTitle: 'Name',
  //         vTitle: 'API endpoint'
  //       },
  //     ],
  //     query: gql`
  //     query custom_api_org {
  //       custom_api_org(
  //         organization_id: "${organization_id}"
  //         ) {
  //         id
  //         name
  //         base_url
  //         auth_method
  //         auth_information
  //         api_endpoints
  //     }
  //     }
  //     `,
  //     mutations: {
  //       create: gql`
  //       mutation createCustomApi(
  //           $organization_id: ID!
  //       ) {
  //           createCustomApi(
  //             organization_id: $organization_id
  //         ) {
  //             id
  //         }  
  //     }`,
  //       update: gql`
  //       mutation updateCustomApi(
  //           $id: ID!,
  //           $name: String,
  //           $base_url: String,
  //           $auth_method: String,
  //           $auth_information: String,
  //           $api_endpoints: String
  //       ) {   
  //           updateCustomApi(
  //             id: $id,
  //             name: $name,
  //             base_url: $base_url,
  //             auth_method: $auth_method,
  //             auth_information: $auth_information,
  //             api_endpoints: $api_endpoints
  //         ) {
  //           id
  //           name
  //           base_url
  //           auth_method
  //           auth_information
  //           api_endpoints
  //         }
  //   }`,
  //       delete: gql`
  //   mutation deleteCustomApi(
  //     $id: ID!
  //   ) {
  //     deleteCustomApi(
  //       id: $id
  //     )
  //   }
  // `
  //     }
  //   },
  {
    type: 'OrganizationAuth',
    display: 'Auth Type',
    fields: [
      {
        key: 'auth_type',
        display: 'Authentication type',
        description: 'Select authentication method for this Organization',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return [
            {
              id: 'auth0',
              name: 'auth0'
            },
            {
              id: 'ticketure',
              name: 'ticketure'
            }
          ]
        }
      },
    ],
    query: gql`
        query organization_auth {
          organization_auth_org(
            organization_id: "${organization_id}"
            ) {
            auth_type
            name
            id
          }
        }
        `,
    mutations: {
      create: gql`
            mutation createAuth(
              $name: String
              $auth_type: String
              $organization_id: ID
            ) {
              createAuth(
                name: $name
                auth_type: $auth_type
                organization_id: $organization_id
              ) {
                name
                auth_type
                organization_id
                id
              }
            }
          `,
      update: gql`
          mutation updateAuth(
            $name: String
            $auth_type: String
            $id: ID!
          ) {
            updateAuth(
              name: $name
              auth_type: $auth_type
              id: $id
            ) {
              name
              auth_type
              organization_id
              id
            }
          }
        `,
      delete: gql`
        mutation deleteAuth(
          $id: ID!
        ) {
          deleteAuth(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationTicketure',
    display: 'Ticketure',
    fields: [
      {
        key: 'authorization',
        display: 'Authorization (Base 64 Encoded)',
        isPasswordType: false
      },
      {
        key: 'email',
        display: 'Admin email',
        isPasswordType: false
      },
      {
        key: 'password',
        display: 'Password for admin email',
        isPasswordType: true
      },
      {
        key: 'api',
        display: 'API Url',
        isPasswordType: false
      },
      {
        key: 'proxy',
        display: 'Proxy Url',
        isPasswordType: false
      }
    ],
    query: gql`
        query organization_ticketure {
          organization_ticketure_org(
            organization_id: "${organization_id}"
            ) {
              authorization
              name
              email
              password
              api
              proxy
              id
          }
        }
        `,
    mutations: {
      create: gql`
            mutation createTicketure(
              $name: String
              $authorization: String
              $email: String
              $password: String
              $api: String
              $proxy: String
              $organization_id: ID
            ) {
              createTicketure(
                name: $name
                authorization: $authorization
                organization_id: $organization_id
                email: $email
                password: $password
                api: $api
                proxy: $proxy
              ) {
                name
                authorization
                organization_id
                id
              }
            }
          `,
      update: gql`
          mutation updateTicketure(
            $name: String
            $authorization: String
            $email: String
            $password: String
            $api: String
            $proxy: String
            $id: ID!
          ) {
            updateTicketure(
              name: $name
              authorization: $authorization
              id: $id
              email: $email
              password: $password
              api: $api
              proxy: $proxy
            ) {
              name
              authorization
              organization_id
              id
            }
          }
        `,
      delete: gql`
        mutation deleteTicketure(
          $id: ID!
        ) {
          deleteTicketure(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationSubscriptions',
    display: 'IAP Subscriptions',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false
      },
      {
        key: 'android_subscription_id',
        display: 'Android Subscription ID',
        isPasswordType: false
      },
      {
        key: 'ios_subscription_id',
        display: 'iOS Subscription ID',
        isPasswordType: false
      },
      {
        key: 'secret_code',
        display: 'Secret Code (Receipt Validation)',
        isPasswordType: false
      },
      {
        key: 'organization_membership_id',
        display: 'Select associated membership',
        description: 'Select which membership integration to use with this integration',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return integrations.filter(el => el.__typename == "OrganizationMemberships")
        }
      }
    ],
    query: gql`
        query organization_subscriptions {
          organization_subscriptions_org(
            organization_id: "${organization_id}"
            ) {
              android_subscription_id
              ios_subscription_id
              secret_code
              name
              id
              organization_membership_id
          }
        }
        `,
    mutations: {
      create: gql`
            mutation createSubscription(
              $name: String
              $android_subscription_id: String
              $ios_subscription_id: String
              $secret_code: String
              $organization_id: ID
              $organization_membership_id: ID
            ) {
              createSubscription(
                name: $name
                android_subscription_id: $android_subscription_id
                ios_subscription_id: $ios_subscription_id
                organization_id: $organization_id
                secret_code: $secret_code
                organization_membership_id: $organization_membership_id
              ) {
                organization_id
                id
              }
            }
          `,
      update: gql`
          mutation updateSubscription(
            $name: String
            $android_subscription_id: String
            $ios_subscription_id: String
            $secret_code: String
            $id: ID!
            $organization_membership_id: ID
          ) {
            updateSubscription(
              name: $name
              android_subscription_id: $android_subscription_id
              ios_subscription_id: $ios_subscription_id
              secret_code: $secret_code
              id: $id
              organization_membership_id: $organization_membership_id
            ) {
              organization_id
              id
            }
          }
        `,
      delete: gql`
        mutation deleteSubscription(
          $id: ID!
        ) {
          deleteSubscription(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationMemberships',
    display: 'External Membership',
    fields: [
      {
        key: 'name',
        display: 'Role (Used for matching existing membership)',
        isPasswordType: false
      },
      {
        key: 'description',
        display: 'Description',
        isPasswordType: false
      },
      {
        key: 'external_source_membership_id',
        display: 'Membership Id from external source',
        isPasswordType: false
      },
      {
        key: 'codes',
        display: 'Discount Code',
        isPasswordType: false
      },
    ],
    query: gql`
        query organization_memberships {
          organization_memberships_org(
            organization_id: "${organization_id}"
            ) {
              description
              name
              external_source_membership_id
              codes
              id
          }
        }
        `,
    mutations: {
      create: gql`
            mutation createMembership(
              $name: String
              $description: String
              $organization_id: ID
              $external_source_membership_id: String
              $codes :String
            ) {
              createMembership(
                name: $name
                description: $description
                organization_id: $organization_id
                external_source_membership_id: $external_source_membership_id
                codes: $codes
              ) {
                name
                organization_id
                id
              }
            }
          `,
      update: gql`
          mutation updateMembership(
            $name: String
            $description: String
            $id: ID!
            $external_source_membership_id: String
            $codes :String
          ) {
            updateMembership(
              name: $name
              description: $description
              id: $id
              external_source_membership_id: $external_source_membership_id
              codes: $codes
            ) {
              name
              organization_id
              id
            }
          }
        `,
      delete: gql`
        mutation deleteMembership(
          $id: ID!
        ) {
          deleteMembership(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationMicrosoftSheets',
    display: 'Microsoft Sheet',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false
      },
      {
        key: 'client_id',
        display: 'Client ID',
        isPasswordType: false,
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'client_secret',
        display: 'Client Secret',
        isPasswordType: false,
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'tenant_id',
        display: 'Tenant ID',
        isPasswordType: false,
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'username',
        display: 'Username',
        isPasswordType: false,
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'password',
        display: 'Password',
        isPasswordType: true,
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'spreadsheet_id',
        display: 'Spreadsheet Id',
        isPasswordType: false
      },
      {
        key: 'sheet_name',
        display: 'Sheet Name',
        isPasswordType: false
      },
      {
        key: 'sync_in_realtime',
        display: 'Sync in real time',
        type: 'toggle',
      },
      {
        key: 'sync_frequency',
        display: 'Sync frequency(Cron Expression)',
        description: 'Cron expression will use to set frequency of sync and works only when sync in real time is off.',
        isPasswordType: false,
        hasHr: true,
        help: {
          url: "https://crontab.cronhub.io/",
          title: "Generate your cron expression here"
        }
      },
      {
        key: 'organization_mongo_id',
        display: 'Mongo Integration',
        description: 'Select which mongo integration to use with this integration',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return integrations.filter(el => el.__typename == "OrganizationMongo")
        }
      }, {
        key: 'data_template',
        display: 'Template Type',
        description: 'Select the template for data type',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return [
            { name: 'Default', id: 'default' },
            { name: 'Employee Work Schedule', id: 'EMPLOYEE_WORK_SCHEDULE_TEMPLATE' },
          ]
        }
      }
    ],
    query: gql`
            query organization_microsoft_sheets_org {
              organization_microsoft_sheets_org(
                organization_id: "${organization_id}"
                ) {
                  id
                  name
                  organization_id
                  client_id
                  client_secret
                  tenant_id
                  sheet_name
                  sync_in_realtime
                  sync_frequency
                  is_ready
                  spreadsheet_id
                  username
                  password
                  organization_mongo_id
                  data_template
                }
             }
            `
    ,
    mutations: {
      create: gql`
                mutation createMicrosoftSheets(
                  $name: String
                  $organization_id: ID
                  $client_id: String
                  $client_secret: String
                  $tenant_id: String
                  $sheet_name:String
                  $sync_in_realtime: Boolean
                  $sync_frequency: String
                  $is_ready: Boolean
                  $spreadsheet_id: String
                  $username: String
                  $password: String
                  $organization_mongo_id: ID
                  $data_template: String
                ) {
                  createMicrosoftSheets(
                    name: $name
                    organization_id: $organization_id
                    client_id: $client_id
                    client_secret: $client_secret
                    tenant_id: $tenant_id
                    sheet_name:$sheet_name
                    sync_in_realtime: $sync_in_realtime
                    sync_frequency: $sync_frequency
                    is_ready: $is_ready
                    spreadsheet_id: $spreadsheet_id
                    username: $username
                    password: $password
                    organization_mongo_id: $organization_mongo_id
                    data_template: $data_template
                ) {
                  organization_id
                  spreadsheet_id
                }
                }
          `,
      update: gql`
      mutation updateMicrosoftSheets(
        $id: ID!
        $name: String
                  $client_id: String
                  $client_secret: String
                  $tenant_id: String
                  $sheet_name:String
                  $sync_in_realtime: Boolean
                  $sync_frequency: String
                  $is_ready: Boolean
                  $spreadsheet_id: String
                  $username: String
                  $password: String
                  $organization_mongo_id: ID
                  $data_template: String
        ) {
          updateMicrosoftSheets(
            id: $id
            name: $name
                    client_id: $client_id
                    client_secret: $client_secret
                    tenant_id: $tenant_id
                    sheet_name:$sheet_name
                    sync_in_realtime: $sync_in_realtime
                    sync_frequency: $sync_frequency
                    is_ready: $is_ready
                    spreadsheet_id: $spreadsheet_id
                    username: $username
                    password: $password
                    organization_mongo_id: $organization_mongo_id
                    data_template: $data_template
        ) {
          id
          spreadsheet_id
        }
        }
        `,
      delete: gql`
        mutation deleteMicrosoftSheets(
          $id: ID!
        ) {
          deleteMicrosoftSheets(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'OrganizationSharePointSheets',
    display: 'Share Point Sheet',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false
      },
      {
        key: 'microsoft_integration_id',
        display: 'Microsoft Integration',
        description: 'Select which microsoft integration to use with this integration',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        disabled: true,
        getOptions: (integrations) => {
          return integrations.filter(el => el.__typename == "MicrosoftIntegrations")
        }
      },
      // {
      //   key: 'client_id',
      //   display: 'Client ID',
      //   isPasswordType: false,
      //   // help: {
      //   //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
      //   //   title: "How to get API Key?"
      //   // }
      // },
      // {
      //   key: 'client_secret',
      //   display: 'Client Secret',
      //   isPasswordType: false,
      //   // help: {
      //   //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
      //   //   title: "How to get API Key?"
      //   // }
      // },
      // {
      //   key: 'tenant_id',
      //   display: 'Tenant ID',
      //   isPasswordType: false,
      //   // help: {
      //   //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
      //   //   title: "How to get API Key?"
      //   // }
      // },
      // {
      //   key: 'username',
      //   display: 'Username',
      //   isPasswordType: false,
      //   // help: {
      //   //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
      //   //   title: "How to get API Key?"
      //   // }
      // },
      // {
      //   key: 'password',
      //   display: 'Password',
      //   isPasswordType: true,
      //   // help: {
      //   //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
      //   //   title: "How to get API Key?"
      //   // }
      // },
      {
        key: 'spreadsheet_id',
        display: 'Spreadsheet Id',
        isPasswordType: false,
        disabled: true
      },
      {
        key: 'sheet_name',
        display: 'Sheet Name',
        isPasswordType: false
      },
      {
        key: 'site_id',
        display: 'Site Id',
        isPasswordType: false,
        disabled: true
      },
      {
        key: 'site_name',
        display: 'Site Name',
        isPasswordType: false,
        disabled: true
      },
      {
        key: 'drive_id',
        display: 'Drive Id',
        isPasswordType: false,
        disabled: true
      },
      {
        key: 'drive_name',
        display: 'Drive Name',
        isPasswordType: false,
        disabled: true
      },
      {
        key: 'sync_in_realtime',
        display: 'Sync in real time',
        type: 'toggle',
      },
      {
        key: 'sync_frequency',
        display: 'Sync frequency(Cron Expression)',
        description: 'Cron expression will use to set frequency of sync and works only when sync in real time is off.',
        isPasswordType: false,
        hasHr: true,
        help: {
          url: "https://crontab.cronhub.io/",
          title: "Generate your cron expression here"
        }
      },
      {
        key: 'organization_mongo_id',
        display: 'Mongo Integration',
        description: 'Select which mongo integration to use with this integration',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return integrations.filter(el => el.__typename == "OrganizationMongo")
        }
      }, {
        key: 'data_template',
        display: 'Template Type',
        description: 'Select the template for data type',
        isPasswordType: false,
        type: 'select',
        hasHr: true,
        valueKey: 'id',
        labelKey: 'name',
        getOptions: (integrations) => {
          return [
            { name: 'Default', id: 'default' },
            { name: 'Employee Work Schedule', id: 'EMPLOYEE_WORK_SCHEDULE_TEMPLATE' },
          ]
        }
      }
    ],
    query: gql`
      query organization_share_point_sheets_org {
        organization_share_point_sheets_org(
          organization_id: "${organization_id}"
        ) {
          id
          name
          organization_id
          client_id
          client_secret
          tenant_id
          sheet_name
          sync_in_realtime
          sync_frequency
          is_ready
          spreadsheet_id
          username
          password
          organization_mongo_id
          data_template
          site_name
          site_id
          drive_id
          drive_name
          microsoft_integration_id
        }
    }
    `
    ,
    mutations: {
      create: gql`
        mutation createSharePointSheets(
          $name: String
          $organization_id: ID
          $client_id: String
          $client_secret: String
          $tenant_id: String
          $sheet_name: String
          $sync_in_realtime: Boolean
          $sync_frequency: String
          $is_ready: Boolean
          $spreadsheet_id: String
          $username: String
          $password: String
          $organization_mongo_id: ID
          $data_template: String
          $site_name: String
          $site_id: ID
          $drive_id: String
          $drive_name: String
          $microsoft_integration_id: ID
        ) {
          createSharePointSheets(
            name: $name
            organization_id: $organization_id
            client_id: $client_id
            client_secret: $client_secret
            tenant_id: $tenant_id
            sheet_name: $sheet_name
            sync_in_realtime: $sync_in_realtime
            sync_frequency: $sync_frequency
            is_ready: $is_ready
            spreadsheet_id: $spreadsheet_id
            username: $username
            password: $password
            organization_mongo_id: $organization_mongo_id
            data_template: $data_template
            site_name: $site_name
            site_id: $site_id
            drive_id: $drive_id
            drive_name: $drive_name
            microsoft_integration_id: $microsoft_integration_id
          ) {
            id
            name
            organization_id
            client_id
            client_secret
            tenant_id
            sheet_name
            sync_in_realtime
            sync_frequency
            is_ready
            spreadsheet_id
            username
            password
            organization_mongo_id
            data_template
            site_name
            site_id
            drive_id
            drive_name
            microsoft_integration_id
          }
        }
      `,
      update: gql`
        mutation updateSharePointSheets(
          $id: ID
          $name: String
          $client_id: String
          $client_secret: String
          $tenant_id: String
          $sheet_name: String
          $sync_in_realtime: Boolean
          $sync_frequency: String
          $is_ready: Boolean
          $spreadsheet_id: String
          $username: String
          $password: String
          $organization_mongo_id: ID
          $data_template: String
          $site_name: String
          $site_id: ID
          $drive_id: String
          $drive_name: String
          $microsoft_integration_id: ID
        ) {
          updateSharePointSheets(
            id: $id
            name: $name
            client_id: $client_id
            client_secret: $client_secret
            tenant_id: $tenant_id
            sheet_name: $sheet_name
            sync_in_realtime: $sync_in_realtime
            sync_frequency: $sync_frequency
            is_ready: $is_ready
            spreadsheet_id: $spreadsheet_id
            username: $username
            password: $password
            organization_mongo_id: $organization_mongo_id
            data_template: $data_template
            site_name: $site_name
            site_id: $site_id
            drive_id: $drive_id
            drive_name: $drive_name
            microsoft_integration_id: $microsoft_integration_id
          ) {
            id
            name
            organization_id
            client_id
            client_secret
            tenant_id
            sheet_name
            sync_in_realtime
            sync_frequency
            is_ready
            spreadsheet_id
            username
            password
            organization_mongo_id
            data_template
            site_name
            site_id
            drive_id
            drive_name
            microsoft_integration_id
          }
        }
      `,
      delete: gql`
        mutation deleteSharePointSheets(
          $id: ID!
        ) {
          deleteSharePointSheets(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'HubspotIntegrations',
    display: 'Hubspot Integrations',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false,
      },
      {
        key: 'client_id',
        display: 'Client ID',
        isPasswordType: false,
        disabled: true
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'client_secret',
        display: 'Client Secret',
        isPasswordType: false,
        disabled: true
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
    ],
    query: gql`
      query hubspot_integrations {
        hubspot_integrations(orgId: "${organization_id}") {
          id
          name
          client_id
          client_secret
          organization_id
          access_token
          refresh_token
        }
      }
    `,
    mutations: {
      create: gql`
        mutation createHubspotIntegration (
          $name: String
          $client_id: String
          $client_secret: String
          $organization_id: String
          $web_redirect_url: String
        ){
          createHubspotIntegration (
            name: $name
            client_id: $client_id
            client_secret: $client_secret
            organization_id: $organization_id
            web_redirect_url: $web_redirect_url
        ){
            hubspot_integration {
              id
              name
              client_id
              client_secret
              organization_id
              access_token
              refresh_token
            }
            redirect_url
            authorization_url
          }
        }
      `,
      update: gql`
        mutation updateHubspotIntegration (
          $id: ID!
          $name: String
          $client_id: String
          $client_secret: StringZ
          $web_redirect_url: String
        ){
          updateHubspotIntegration (
            id: $id
            name: $name
            client_id: $client_id
            client_secret: $client_secret
            web_redirect_url: $web_redirect_url
        ){
            hubspot_integration {
              id
              name
              client_id
              client_secret
              organization_id
              access_token
              refresh_token
            }
            redirect_url
            authorization_url
          }
        }
      `,
      delete: gql`
        mutation deleteHubspotIntegration(
          $id: ID!
        ) {
          deleteHubspotIntegration(
            id: $id
          )
        }
      `
    }
  },
  {
    type: 'MicrosoftIntegrations',
    display: 'Microsoft Integrations',
    fields: [
      {
        key: 'name',
        display: 'Name',
        isPasswordType: false,
      },
      {
        key: 'client_id',
        display: 'Client ID',
        isPasswordType: false,
        disabled: true
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'client_secret',
        display: 'Client Secret',
        isPasswordType: false,
        disabled: true
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
      {
        key: 'tenant_id',
        display: 'Tenant ID',
        isPasswordType: false,
        disabled: true,
        // help: {
        //   url: "https://cloud.google.com/docs/authentication/api-keys?visit_id=637584957493039168-662829081&rd=1",
        //   title: "How to get API Key?"
        // }
      },
    ],
    query: gql`
      query microsoft_integrations {
        microsoft_integrations(orgId: "${organization_id}") {
          id
          name
          client_id
          tenant_id
          client_secret
          organization_id
          access_token
          refresh_token
        }
      }
    `,
    mutations: {
      create: gql`
        mutation createMicrosoftIntegration (
          $name: String
          $client_id: String
          $tenant_id: String
          $client_secret: String
          $organization_id: String
          $access_token: String
          $refresh_token: String
        ){
          createMicrosoftIntegration (
            name: $name
            client_id: $client_id
            tenant_id: $tenant_id
            client_secret: $client_secret
            organization_id: $organization_id
            access_token: $access_token
            refresh_token: $refresh_token
        ){
          id
          name
          client_id
          tenant_id
          client_secret
          organization_id
          access_token
          refresh_token
          }
        }
      `,
      update: gql`
        mutation updateMicrosoftIntegration (
          $id: ID
          $name: String
          $client_id: String
          $tenant_id: String
          $client_secret: String
        ){
          updateMicrosoftIntegration (
            id: $id
            name: $name
            client_id: $client_id
            tenant_id: $tenant_id
            client_secret: $client_secret
        ){
          id
          name
          client_id
          tenant_id
          client_secret
          organization_id
          access_token
          refresh_token
          }
        }
    `,
      delete: gql`
        mutation deleteMicrosoftIntegration(
          $id: ID!
        ) {
          deleteMicrosoftIntegration(
            id: $id
          )
        }
      `
    }
  }
];

export default INTEGRATIONS_OPTIONS;