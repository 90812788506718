import React, { useState, useEffect } from 'react'
import { Input } from 'baseui/input'
import { Button } from 'baseui/button'
import { BsFillPlusSquareFill, BsXSquare } from "react-icons/bs";
import { LabelSmall } from 'baseui/typography';

import { Modal, ModalHeader, ModalFooter, ModalButton, ModalBody } from 'baseui/modal';
import axios from 'axios';

let inputStyle = {
    float: 'left',
    display: 'inline-block',
    margin: '8px',
}
let titleSyle = {
    float: 'left',
    display: 'inline-block',
    width: '40%',
    margin: '8px',
}


function KeyValueEditor(props) {
    const [pair, setPair] = useState([])
    const [isOpen, setOpen] = useState()
    const { fieldIndex, fieldData, onChange, value } = props
    const [apiRes, setApiRes] = useState()

    useEffect(() => {
        if (value[fieldData.key]) {
            let val = JSON.parse(value[fieldData.key])
            setPair(val)
        } else {
            addPair()
        }
    }, [])

    const addPair = () => {
        let set = {
            [fieldData.nKey]: '',
            [fieldData.vKey]: '/'
        }
        setPair([...pair, set])
    }

    useEffect(() => {
        onChange(fieldIndex, fieldData, JSON.stringify(pair))
    }, [pair])

    const onInputChange = (id, value, index) => {
        let pairCopy = JSON.parse(JSON.stringify(pair))
        if (id.startsWith("key")) {
            pairCopy[index][fieldData.nKey] = value
        } else if (id.startsWith("value")) {
            pairCopy[index][fieldData.vKey] = value
        }
        setPair(pairCopy)
    }

    const onDelete = (index) => {
        let pairCopy = JSON.parse(JSON.stringify(pair))
        pairCopy.splice(index, 1)
        setPair(pairCopy)
    }

    const onTest = (index) => {
        axios.get(`${value.base_url}${pair[index][fieldData.vKey]}`)
            .then(function (response) {
                response && setApiRes(response)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                setOpen(true)
            });
    };
    
    const ReactJson = typeof window !== `undefined` ? require('react-json-view') : <></>;
    return (
        <div>
            <div>
                <div style={titleSyle}>
                    <LabelSmall>{fieldData.nTitle}</LabelSmall>
                </div>
                <div style={titleSyle}>
                    <LabelSmall>{fieldData.vTitle}</LabelSmall>
                </div>
            </div>
            {pair && pair.map((set, index) => (
                <div>
                    <div style={inputStyle}>
                        <Input id={"key" + index} onChange={(e) => onInputChange(e.target.id, e.target.value, index)} value={set[fieldData.nKey]} placeholder='Enter the name' />
                    </div>
                    <div style={inputStyle}>
                        <Input id={"value" + index} onChange={(e) => onInputChange(e.target.id, e.target.value, index)} value={set[fieldData.vKey]} style={inputStyle} placeholder='Enter API endpoint' />
                    </div>
                    <div style={{
                        float: 'left',
                        margin: '8px',
                        display: 'inline-block',
                    }}>
                        <Button onClick={() => onTest(index)}>Test</Button>
                    </div>
                    <div style={{
                        float: 'left',
                        margin: '8px',
                        display: 'inline-block'
                    }}>
                        <Button startEnhancer={() => <BsXSquare size={16} />} onClick={() => onDelete(index)}>
                            Remove
        </Button>

                    </div>
                </div>
            ))}

            <Button startEnhancer={() => <BsFillPlusSquareFill size={16} />} style={{ width:'100%', marginTop: '16px' }} onClick={addPair}>Add new pair</Button>
            <React.Fragment>
                <Modal
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOpen={isOpen}
                >
                    <ModalHeader>API Response</ModalHeader>
                    <ModalBody>
                        <ReactJson src={apiRes}
                            enableClipboard={false}
                            collapsed={true} />
                        {/*JSON.stringify(apiRes)*/}
                    </ModalBody>
                    <ModalFooter>
                        <ModalButton
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Okay
            </ModalButton>
                    </ModalFooter>
                </Modal>
            </React.Fragment>

        </div>
    )
}

export default KeyValueEditor
