import * as React from 'react';
import { apiQuery } from '../apiMappings/query';
import { client } from '../../apollo/client';
import { Tabs, Tab, FILL } from 'baseui/tabs-motion';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Grid, Cell } from 'baseui/layout-grid';
import { Spinner } from 'baseui/spinner';
import Button from '../styledButton';
import { useStyletron } from 'baseui';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Select } from 'baseui/select';
import { Input } from 'baseui/input';
import TextArea from '../channel/textarea';
import { createCommon, updateCommon } from '../apiMappings/query';
import styled from '@emotion/styled';
import { Accordion, Panel } from 'baseui/accordion';
import { H6, H5 } from 'baseui/typography';
import { showAlert } from '../../redux/actions/appBasicControls';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

const DeleteButton = styled(Button)`
  background-color: #ef4778;

  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 16px;
  border-radius: 20px;
  font-size: 12px;
`;

export default ({
  apis,
  queryData,
  isLoading,
  setApis,
  organization_id,
  setIsLoading,
  dispatch,
  refetch
}) => {
  const [css, theme] = useStyletron();
  const [selected, setSelected] = React.useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isModalLoading, setIsModalLoading] = React.useState(false);
    const [isSaveLoading, setIsSaveLoading] = React.useState(false);
  console.log('____', apis);
  const setValue = (index, key, value) => {
    let v1 = JSON.parse(JSON.stringify(apis));
    v1[index][key] = value;
    v1[index].isUpdated = true;
    setApis(v1);
  };

  const addNew = () => {
    setIsLoading(true);
    client
      .mutate({
        mutation: createCommon,
        variables: {
          data: {
            model: 'organization_apis',
            data: JSON.stringify({
              base_api: '',
              headers: '{}',
              metadata: '{}',
              name: '',
              organization_id,
            }),
          },
        },
      })
      .then(() => queryData());
  };

  const save = async () => {
    setIsLoading(true);
    let toSave = apis.filter(el => el.isUpdated);
    await Promise.all(
      toSave.map(s => {
        return client.mutate({
          mutation: updateCommon,
          variables: {
            data: {
              model: 'organization_apis',
              data: JSON.stringify({
                ...s,
              }),
            },
          },
        });
      })
    );
    queryData();
    await refetch();
    setIsSaveLoading(false);
    dispatch(
      showAlert({
        msg: 'Successfully saved API!',
        error: false,
      })
    );
  };

  const onDelete = async () => {
   console.log("value deleted @kartik")
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell span={12} align={'right'}>
        <div
          className={css({
            marginRight: '16px',
            position: 'relative',
            float: 'right',
            marginBottom: '16px',
          })}
        >
          <Button
            isLoading={false}
            onClick={() => {
              addNew();
            }}
            isSelected
          >
            Add New
          </Button>
        </div>
        {isLoading && (
          <div
            className={css({
              position: 'relative',
              float: 'right',
              marginRight: '16px',
              marginTop: '18px',
            })}
          >
            <Spinner size={24} />
          </div>
        )}
      </Cell>
      <Accordion onChange={({ expanded }) => console.log(expanded)}>
        {apis.map((api, index) => {
          return (
            <Panel
              overrides={{
                Content: {
                  style: ({ $theme }) => ({
                    paddingLeft: '0 !impmortant',
                  }),
                },
              }}
              key={index}
              title={api.name ? api.name : '...'}
            >
              <Cell span={12}>
                <div
                  style={{
                    background: theme.colors.primaryB,
                    padding: '24px',
                  }}
                >
                  <div>
                    <LabelMedium
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                      Name
                    </LabelMedium>
                    <Input
                      value={api.name}
                      onChange={e => {
                        setValue(index, 'name', e.target.value);
                      }}
                    />
                  </div>

                  <div>
                    <LabelMedium
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                      Base Api
                    </LabelMedium>
                    <Input
                      value={api.base_api}
                      onChange={e => {
                        setValue(index, 'base_api', e.target.value);
                      }}
                    />
                  </div>

                  <div>
                    <LabelMedium
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                      Headers
                    </LabelMedium>
                    <Input
                      value={api.headers}
                      onChange={e => {
                        setValue(index, 'headers', e.target.value);
                      }}
                      textAreaKey={'base-api-settings'}
                    />
                  </div>

                  <div>
                    <LabelMedium
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                      Metadata
                    </LabelMedium>
                    <Input
                      value={api.metadata}
                      onChange={e => {
                        setValue(index, 'metadata', e.target.value);
                      }}
                      textAreaKey={'base-api-settings'}
                    />
                  </div>
                  <div style={{ position: 'relative', float: 'right' }}>
                    <Button
                      isLoading={false}
                      onClick={() => {
                        save();
                      }}
                      isSelected
                    >
                      Save
                    </Button>
                  </div>

                  <div style={{ position: 'relative', float: 'right' }}>
                    <DeleteButton style={{ marginLeft: '16px' }} onClick={() => {
                      setSelected(api)
                      setIsDeleteOpen(true);
                    }} style={{ marginLeft: '16px' }} isSelected>
                      Delete
                    </DeleteButton>
                  </div>
                </div>
              </Cell>
            </Panel>
          );
        })}
      </Accordion>

      <React.Fragment>
        <Modal
          onClose={() => {
            setIsDeleteOpen(false);
            setSelected(null);
          }}
          isOpen={isDeleteOpen}
        >
          <ModalHeader>
            Are you sure that you want to delete this Integration?
          </ModalHeader>
          <ModalBody>
            <H6>This action can not be undone.</H6>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => {
                setSelected(null);
                setIsDeleteOpen(false);
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton
              isLoading={isModalLoading}
              onClick={() => {
                if (selected) {
                  onDelete();
                }
              }}
            >
              Okay
            </ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    </Grid>
  );
};
