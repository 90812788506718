import React from 'react';
import { SketchPicker } from 'react-color';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';

const ColorPicker = ({color, onChange}) => {
    const [css, theme] = useStyletron();
    return <StatefulPopover
      content={() => (
        <SketchPicker
          color={color}
          onChangeComplete={color => {
            onChange(color.hex);
          }}
        />
      )}
      placement={PLACEMENT['bottomRight']}
      returnFocus
      autoFocus
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            marginTop: '50px',
            zIndex: 5
          }),
        },
      }}
    >
      <div
        key={color}
        className={css({
          width: 'calc((100% - 96px - 32px) / 7)',
          height: '24px',
          // position: 'relative',
          // float: 'left',
          borderRadius: '4px',
          border: '1px solid'
        })}
        style={{
          background: color,
        }}
      ></div>
    </StatefulPopover>
  }

  export default ColorPicker;