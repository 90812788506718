import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Accordion, Panel } from "baseui/accordion";
import { H6, H5 } from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import { Button as BaseButton } from 'baseui/button';
import Button from '../styledButton';
import { StatefulSelect } from 'baseui/select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import 'react-toggle/style.css';
import { showAlert } from '../../redux/actions/appBasicControls';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import IntegrationOptions from './integration-options';
import { client } from '../../apollo/client';

import styled from '@emotion/styled';
import MicrosoftIntegrationModal from '../modals/microsoft-integration-modal';
import { renderInputField, renderKeyValueField, renderSelectField, renderToggleField } from './common-components/CustomRenderFunctions';
import AddSharePointSheetModal from '../modals/microsoft-sharepoint_integration';
import HubspotIntegrationModal from '../modals/hubspot-integration-modal';


const DeleteButton = styled(BaseButton)`
  background-color: #EF4778;
  
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 16px;
  border-radius: 20px;
  font-size: 12px;
`;

const IntegrationSettings = ({
  organization,
  refetch,
  dispatch
}) => {
  const [css, theme] = useStyletron();
  const [integrations, setIntegrations] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSharePointOpen, setIsSharePointOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const [isLoadingData, setIsLoadingData] = React.useState(true);
  const [isSaveLoading, setIsSaveLoading] = React.useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const [isModalLoading, setIsModalLoading] = React.useState(false);
  const [sharePointAuth, setSharePointAuth] = React.useState({});
  const [selectedModalData, setSelectedModalData] = React.useState({});
  const [isMsIntegrationModalOpen, setIsMsIntegrationModalOpen] = React.useState(false);
  const [isHubspotIntegrationModalOpen, setIsHubspotIntegrationModalOpen] = React.useState(false);

  const fetchIntegrations = () => {
    return Promise.all(IntegrationOptions(organization.id).map((el) => {
      return client.query({ query: el.query, fetchPolicy: 'network-only' })
        .then((data) => {
          return data.data[Object.keys(data.data)[0]];
        })
    }))
      .then((data) => {
        setIsLoadingData(false);
        let allIntegrations = [];
        data.forEach((el) => {
          allIntegrations = [...allIntegrations, ...el];
        });
        setIntegrations(allIntegrations);
      })
  };

  React.useEffect(() => {
    fetchIntegrations();
  }, []);

  const addNewIntegration = async () => {
    setIsModalLoading(true);
    await client.mutate({
      mutation: selected.mutations.create,
      variables: {
        organization_id: organization.id,
        ...selected.fields.reduce((a, b) => {
          a[b.key] = b.value;
          return a;
        }, {})
      }
    })
    await fetchIntegrations();
    await refetch();
    setIsModalLoading(false);
    setIsOpen(false);
    setSelected(null);
  };

  const setValueAtIndex = (integrationIndex, field, value) => {
    let newData = JSON.parse(JSON.stringify(integrations));
    let integration = newData[integrationIndex];
    integration[field.key] = value;
    integration.isUpdated = true;
    newData[integrationIndex] = integration;
    setIntegrations(newData);
  }

  const getIntegrationOption = (integration) => {
    return IntegrationOptions(organization.id).find((el) => el.type == integration.__typename)
  };

  const onSave = async () => {
    let newData = JSON.parse(JSON.stringify(integrations));
    let dataToSave = newData.filter(el => el.isUpdated);
    setIsSaveLoading(true);
    await Promise.all(dataToSave.map((el) => {
      let option = getIntegrationOption(el);
      let variables = el;
      delete el.isUpdated;
      delete el.__typename;
      return client.mutate({
        mutation: option.mutations.update,
        variables
      })
    }));
    await refetch();
    setIsSaveLoading(false);
    dispatch(
      showAlert({
        msg: 'Successfully saved integration!',
        error: false,
      })
    );
  }

  const onEdit = (integrationIndex) => {
    let newData = JSON.parse(JSON.stringify(integrations));
    let integration = newData[integrationIndex];
    delete integration.isUpdated;
    delete integration.__typename;
    setSharePointAuth(integration);
    // setTimeout(() => {
    //   getSharePointSiteList();
    //   if (integration.site_id) {
    //     getSharePointDriveList(integration.site_id);
    //   }
    //   if (integration.drive_id) {
    //     getSharePointDriveItemList(integration.drive_id);
    //   }
    // }, 5000);
    setIsSharePointOpen(true);
  }

  const onEditMsIntegration = (integrationIndex) => {
    let newData = JSON.parse(JSON.stringify(integrations));
    let integration = newData[integrationIndex];
    delete integration.isUpdated;
    delete integration.__typename;
    setSelectedModalData(integration);
    setIsMsIntegrationModalOpen(true);
  }

  const onEditHubspotIntegration = (integrationIndex) => {
    let newData = JSON.parse(JSON.stringify(integrations));
    let integration = newData[integrationIndex];
    delete integration.isUpdated;
    delete integration.__typename;
    setSelectedModalData(integration);
    setIsHubspotIntegrationModalOpen(true);
  }

  const onDelete = async () => {
    setIsModalLoading(true);
    const integration = selected;
    let option = getIntegrationOption(integration);
    const variables = {
      id: integration.id
    }
    await client.mutate({
      mutation: option.mutations.delete,
      variables
    }).catch(err => console.log('error', err))
    .finally(() => {
      setIsModalLoading(false);
    })
    await fetchIntegrations();
    await refetch();
    setIsModalLoading(false);
    setIsDeleteOpen(false);
    setSelected(null);
    dispatch(
      showAlert({
        msg: 'Successfully deleted integration!',
        error: false,
      })
    );
  }

  const onCloseSharePointModal = () => {
    setIsOpen(false);
    setIsSharePointOpen(false);
    setSelected(null);
    setSharePointAuth({});
  }

  const onSuccessSharePointModal = async () => {
    await fetchIntegrations();
    await refetch();
    setIsOpen(false);
    setIsSharePointOpen(false);
    setSelected(null);
    setSharePointAuth({});
  }

  const onSuccessMsIntegrationModal = async () => {
    await fetchIntegrations();
    await refetch();
    setIsOpen(false);
    setSelected(null);
    dispatch(showAlert({
      msg: 'Successfully Added',
      error: false
    }));
    setSelectedModalData({});
    setIsMsIntegrationModalOpen(false);
    setIsHubspotIntegrationModalOpen(false)
  }

  /////////////////////////////////////////////// Render Functions //////////////////////////////////////////////////////



  if (isLoadingData) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(10).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '1rem' }}>
                {new Array(1).fill('').map(item => <Skeleton
                  width="100%"
                  height="60px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    );
  }



  const renderFieldByType = (index, integration, field) => {
    const type = field.type || 'input';
    let onChange = e => {
      setValueAtIndex(index, field, e.target.value)
    };;

    let fieldData = {
      ...field,
      value: integration[field.key]
    }
    console.log('rendered Integration')
    switch (type) {
      case 'toggle':
        onChange = e => {
          setValueAtIndex(index, field, e.target.checked)
        }
        return renderToggleField(fieldData, onChange);

      case 'select':
        let optionVal = field.getOptions(integrations).filter((el) => {
          return el[field.valueKey] == integration[field.key];
        })
        let options = field.getOptions(integrations);
        fieldData['optionValue'] = optionVal;
        fieldData['optionValues'] = options;
        onChange = event => {
          if (event.option && event.option.id) {
            return setValueAtIndex(index, field, event.option.id)
          }
          setValueAtIndex(index, field, null);
        }
        return renderSelectField(fieldData, onChange);

      case 'keyvalue':
        onChange = setValueAtIndex
        return renderKeyValueField(index, integration, field, onChange);

      default: return renderInputField(fieldData, onChange);
    }
  }



  console.log('Site List', sharePointAuth['site_id'])

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div style={{ position: 'relative', float: 'right' }}>
          {/* <Button style={{ marginRight: '10px' }} onClick={() => setIsHubspotIntegrationModalOpen(true)} isSelected>Add Hubspot Integration</Button> */}
          <Button onClick={() => setIsOpen(true)} isSelected>Add New</Button>
        </div>
      </Cell>
      <div style={{ marginTop: '16px', width: '100%' }}>
        <Grid overrides={gridPaddingOverrides}>
          <Accordion
            onChange={({ expanded }) => console.log(expanded)}
          >
            {integrations.map((integration, index) => {
              let option = getIntegrationOption(integration);
              const onEditClick = () => { 
                if (option && option.type === 'OrganizationSharePointSheets') {
                onEdit(index) }
                else if (option && option.type === 'MicrosoftIntegrations') {
                  onEditMsIntegration(index)
                } else if (option && option.type === 'HubspotIntegrations') {
                  onEditHubspotIntegration(index)
                }
              }
              return <Panel overrides={{
                Content: {
                  style: ({ $theme }) => ({
                    paddingLeft: '0 !impmortant'
                  })
                }
              }} key={index} title={option.display + ' ' + (index + 1)}>
                <Cell overrides={cellPaddingOverrides} span={12}>
                  {
                    option && (option.type === 'OrganizationSharePointSheets' || option.type === 'MicrosoftIntegrations' || option.type === 'HubspotIntegrations') && (
                      <div style={{ position: 'relative', float: 'right' }}>
                        <Button isLoading={isSaveLoading} onClick={onEditClick} style={{ marginLeft: '16px' }} isSelected>Edit</Button>
                      </div>
                    )
                  }
                  <div style={{ position: 'relative', float: 'right' }}>
                    <Button isLoading={isSaveLoading} onClick={onSave} style={{ marginLeft: '16px' }} isSelected>Save</Button>
                  </div>
                  <div style={{ position: 'relative', float: 'right' }}>
                    <DeleteButton isLoading={isDeleteLoading} onClick={() => {
                      setSelected(integration)
                      setIsDeleteOpen(true);
                    }} style={{ marginLeft: '16px' }} isSelected>Delete</DeleteButton>
                  </div>
                </Cell>
                <Cell overrides={cellPaddingOverrides} span={6}>
                  <div
                    className={css({
                      // padding: '24px',
                      // background: theme.colors.primaryB,
                      marginBottom: '24px',
                      marginLeft: index % 2 === 0 ? '0px' : '12px',
                      marginRight: index % 2 === 0 ? '12px' : '0px',
                      // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                    })}
                  >
                    {option.fields.map((field, index1) => renderFieldByType(index, integration, field))}
                  </div>
                </Cell>
              </Panel>
            })}
          </Accordion>
        </Grid>
      </div>
      <React.Fragment>
        <Modal
          onClose={() => {
            setIsOpen(false);
            setSelected(null);
          }}
          isOpen={isOpen}
        >
          <ModalHeader>Which Integration to add?</ModalHeader>
          <ModalBody>
            <StatefulSelect
              options={IntegrationOptions(organization.id)}
              labelKey="display"
              valueKey="display"
              onChange={event => {
                setSelected(event.option);
              }}
              overrides={{
                Popover: {
                  props: {
                    overrides: {
                      Body: { style: { zIndex: 100 } },
                    },
                  },
                },
              }}
              getOptionLabel={({ option }) => option.selectDropDownDisplay || option.display}
            />
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => {
                setSelected(null);
                setIsOpen(false);
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton
              isLoading={isModalLoading}
              onClick={() => {
                if (selected && selected.type === 'OrganizationSharePointSheets') {
                  setIsSharePointOpen(true);
                } else if (selected && selected.type === 'MicrosoftIntegrations') {
                  setIsMsIntegrationModalOpen(true);
                } else if (selected && selected.type === 'HubspotIntegrations') {
                  setIsHubspotIntegrationModalOpen(true);
                } else if (selected) {
                  addNewIntegration();
                }
              }}
            >
              Okay
            </ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
      <React.Fragment>

        <Modal
          onClose={() => {
            setIsDeleteOpen(false);
            setSelected(null);
          }}
          isOpen={isDeleteOpen}
        >
          <ModalHeader>Are you sure that you want to delete this Integration?</ModalHeader>
          <ModalBody>
            <H6>
              This action can not be undone.
            </H6>
          </ModalBody>
          <ModalFooter>
            <ModalButton
              onClick={() => {
                setSelected(null);
                setIsDeleteOpen(false);
              }}
            >
              Cancel
            </ModalButton>
            <ModalButton
              isLoading={isModalLoading}
              onClick={() => {
                if (selected) {
                  onDelete();
                }
              }}
            >
              Okay
            </ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
      <AddSharePointSheetModal
        integrations={IntegrationOptions(organization.id).filter(integration => integration.type === 'OrganizationSharePointSheets')}
        integrationData={sharePointAuth}
        dispatch={dispatch}
        organization={organization}
        isModalOpen={isSharePointOpen}
        onClose={onCloseSharePointModal}
        onSuccess={onSuccessSharePointModal}
      />
      <MicrosoftIntegrationModal
        isModalOpen={isMsIntegrationModalOpen}
        integrationData={selectedModalData}
        onClose={() => setIsMsIntegrationModalOpen(false)}
        onSuccess={onSuccessMsIntegrationModal}
      />
      <HubspotIntegrationModal
        isModalOpen={isHubspotIntegrationModalOpen}
        integrationData={selectedModalData}
        onClose={() => setIsHubspotIntegrationModalOpen(false)}
        onSuccess={onSuccessMsIntegrationModal}
      />
    </Grid>
  );
};

export default IntegrationSettings;
