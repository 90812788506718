import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelMedium,
} from 'baseui/typography';
import {Skeleton} from 'baseui/skeleton'
import Button from '../styledButton';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';
import './attribute.css';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import { getSpecification, createSpecification, updateSpecification, createSpecificationValue, updateSpecificationValue } from '../../quries';
import { Spinner } from 'baseui/spinner';
import { StatefulPopover } from "baseui/popover";
import { DeleteAlt } from "baseui/icon";
import { showAlert } from '../../redux/actions/appBasicControls';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";

const AttributeSettings = ({ organization, toaster, dispatch }) => {
  const [css, theme] = useStyletron();
  const [attributes, setAttributes] = React.useState([]);
  const organization_id = organization.id
  const [isAddNewLoading, setAddNewLoading] = React.useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false);
  const [isLoadingSpec, setIsLoadingSpecs] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);


  const loadSpecs = () => {
    setIsLoadingSpecs(true);
    client.query({
      query: getSpecification(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setIsLoadingSpecs(false);
      setIsUpdateLoading(false);
      setAddNewLoading(false);
      setAttributes(data.specifications_org);
    })
  };

  const createNewSpec = async () => {
    setAddNewLoading(true);
    let toastKey = toaster.info('Creating new filter');
    await client.mutate({
      mutation: createSpecification,
      variables: {
        name: 'New',
        organization_id: organization_id
      }
    });
    toaster.clear(toastKey);
    loadSpecs();
  };


  const onSave = async () => {
    setIsUpdateLoading(true);
    let toastKey = toaster.info('Updating filter values');
    let specificationsToSave = attributes.filter(el => el.isUpdated);
    let specificationsValuesToSave = attributes.filter(
      el => el.isValuesUpdated
    );

    await Promise.all(specificationsToSave.map((el) => {
      return client.mutate({
        mutation: el.id ? updateSpecification : createSpecification,
        variables: {
          id: el.id,
          name: el.name,
          name: el.name,
          organization_id: organization_id,
          isDeleted: el.isDeleted,
        }
      })
    }));


    for (var i = 0; i < specificationsValuesToSave.length; i++) {
      let specification_values = specificationsValuesToSave[i].specification_values;
      specification_values = specification_values.filter((el) => {
        return el.isUpdated || (el.name == el.id) || el.isDeleted;
      });
      specification_values = specification_values.filter((el) => {
        return el.id == el.name ? (el.isDeleted ? false : true) : true;
      });
      await Promise.all(specification_values.map((el) => {
        return client.mutate({
          mutation: el.name == el.id ? createSpecificationValue : updateSpecificationValue,
          variables: {
            name: el.name,
            specification_id: specificationsValuesToSave[i].id,
            organization_id: organization_id,
            isDeleted: el.isDeleted,
            id: el.id
          }
        })
      }))
    };
    toaster.clear(toastKey);
    loadSpecs();

    dispatch(
      showAlert({
        msg: 'The data filter changes have been saved.',
        error: false,
      })
    );
  };

  React.useEffect(() => {
    loadSpecs();
  }, []);

  console.log(attributes);

  const getSpecValueLabel = ({ option }) => {
    let index = option.specIndex;
    let i = option.specValueIndex;
    return (
      <StatefulPopover
        content={() => (
          <div style={{ padding: '16px' }}>
            <div style={{ marginBottom: '8px' }}>Update Value</div>
            <Input placeholder="Focusable Element" value={option.name} onChange={(e) => {
              let a = JSON.parse(JSON.stringify(attributes));
              a[index].specification_values[i].name = e.target.value;
              a[index].isValuesUpdated = true;
              a[index].specification_values[i].isUpdated = true;
              setAttributes(a);
            }} />
          </div>
        )}
        showArrow
        returnFocus
        autoFocus
        focusLock={true}
      >
        <div>{option.name}</div>
      </StatefulPopover>
    );
  }

  if (isLoadingSpec) {
    return (
      <div style={{ width: '100%', marginTop: '1rem'}}>
        <div style={{marginLeft: '2rem', marginTop: '1rem'}}>
          {new Array(5).fill('').map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', marginTop: '2rem'}}>
                {new Array(2).fill('').map(item => <Skeleton
                  width="45%"
                  height="60px"
                  animation
                  overrides={{
                      Root: {
                          style: {
                            marginRight: '60px'
                          },
                      },
                  }}
                />)}
              </div>
            )
          })}
      </div>
    </div>
    )
  }

  return (
    <div style={{marginTop: '2rem'}}>
    <Grid overrides={gridPaddingOverrides}>
      <Cell span={12} align={'right'}>
        <div
          className={css({
            position: 'relative',
            float: 'right',
          })}
        >
          <Button
            onClick={() => {
              onSave();
            }}
            isSelected
            isLoading={isUpdateLoading}
          >
            Save
          </Button>
        </div>
        <div
          className={css({
            marginRight: '16px',
            position: 'relative',
            float: 'right',
            marginBottom: '16px'
          })}
        >
          <Button
            isLoading={isAddNewLoading}
            onClick={createNewSpec}
            isSelected
          >
            Add New
          </Button>
        </div>
        {isLoadingSpec && <div
          className={css({
            position: 'relative',
            float: 'right',
            marginRight: '16px', marginTop: '18px'
          })}
        >
          <Spinner size={24} />
        </div>}
      </Cell>
      <Cell overrides={cellPaddingOverrides} span={12}>
        {/* NAME */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div style={{ display: 'flex', }}>
          <LabelMedium
            style={{
              marginBottom: '16px',
              width: '50%'
              // marginTop: '48px',
            }}
          >
            Filter Group
          </LabelMedium>
          <LabelMedium
            style={{
              marginBottom: '16px',
              // marginTop: '48px',
            }}
          >
            Filter Items
          </LabelMedium>
          </div>
          {attributes.filter(el => !el.isDeleted).map((attr, index) => {
            return (
              <div
                key={index}
                className={css({
                  display: 'flex',
                  marginBottom: '16px',
                })}
              >
                <Cell
                  span={6}
                  overrides={{
                    Cell: { style: { paddingLeft: '0px !important' } },
                  }}
                >
                  <Input
                    value={attr.name}
                    onChange={e => {
                      let a = JSON.parse(JSON.stringify(attributes));
                      a[index].name = e.target.value;
                      a[index].isUpdated = true;
                      setAttributes(a);
                    }}
                    placeholder="Name of attribute"
                    clearOnEscape
                  />
                </Cell>
           
                <Cell
                  span={6}
                  overrides={{
                    Cell: { style: { paddingRight: '0px !important' } },
                  }}
                >
                  <Select
                    creatable
                    multi
                    options={[
                      {
                        name: 'New Title',
                        id: 'New Title',
                        name_html: 'New Title'
                      },
                    ]}
                    labelKey="name"
                    placeholder="Type name of title"
                    valueKey="id"
                    backspaceRemoves={false}
                    overrides={{
                      Tag: {
                        onMouseEnter: () => console.log('on me'),
                        props: {
                          overrides: {
                            Root: {
                              props: {
                                onMouseEnter: e => {
                                  let target = e.target;
                                  try {
                                    target.firstChild.classList.add(
                                      'full-width-tag'
                                    );
                                  } catch (e) { }
                                },
                                onMouseLeave: e => {
                                  let target = e.target;
                                  try {
                                    target.firstChild.classList.remove(
                                      'full-width-tag'
                                    );
                                  } catch (e) { }
                                },
                              },
                            },
                          },
                        },
                      },
                    }}
                    onChange={({ value, ...rest }) => {
                      console.log(value, rest);
                      let a = JSON.parse(JSON.stringify(attributes));
                      if (rest.type == 'remove') {
                          let removedId = rest.option.id;
                          let valIndex = a[index].specification_values.findIndex(el1 => el1.id == removedId);
                          a[index].specification_values[valIndex].isDeleted = true;
                      } else if (rest.type == 'clear') {
                        const specification_values = a[index].specification_values.map((item, index) => ({ ...item, isDeleted: true }))
                        a[index].specification_values = specification_values
                      } else {
                        // new option created
                        a[index].specification_values.push({
                          id: rest.option.id,
                          name: rest.option.name
                        });
                      }
                      a[index].isValuesUpdated = true;
                      setAttributes(a);
                    }}
                    value={attr.specification_values.map((el, i) => {
                      el = {...el}
                      el.specIndex = index;
                      el.specValueIndex = i;
                      return el;
                    }).filter(
                      el => !el.isDeleted
                    )}
                    getValueLabel={getSpecValueLabel}
                  />
                </Cell>
                <div style={{ marginLeft: '8px', marginTop: '10px', cursor: 'pointer' }}>
                    <DeleteAlt size={32} onClick={() => setIsOpen(true)} />
                </div>
                <Modal
                  onClose={() => setIsOpen(false)}
                  closeable
                  isOpen={isOpen}
                  animate
                  autoFocus
                  size={SIZE.default}
                  role={ROLE.dialog}
                >
                  <ModalHeader>Confirm</ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete this filter?
                  </ModalBody>
                  <ModalFooter>
                    <ModalButton kind={ButtonKind.tertiary} onClick={() => setIsOpen(false)}>
                      Cancel
                    </ModalButton>
                    <ModalButton onClick={() => {
                        let a = JSON.parse(JSON.stringify(attributes));
                        a[index].isDeleted = true;
                        a[index].isUpdated = true;
                        setAttributes(a);
                        setIsOpen(false)
                    }}>Delete</ModalButton>
                  </ModalFooter>
                </Modal>
              </div>
            );
          })}
        </div>
      </Cell>
    </Grid>
    </div>
  );
};

export default AttributeSettings;
