import * as React from 'react';
import { Input } from 'baseui/input';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { LabelSmall, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { Toast, KIND, ToasterContainer } from 'baseui/toast';

import { client } from '../../apollo/client';
import { createMicrosoftIntegration, updateMicrosoftIntegration } from '../settings/queries';
import { connect } from 'react-redux';
import { showAlert } from '../../redux/actions/appBasicControls';
const fields = [
  {
    key: 'name',
    display: 'Name',
    isPasswordType: false
  },
  {
    key: 'client_id',
    display: 'Client ID',
    isPasswordType: false,

  },
  {
    key: 'client_secret',
    display: 'Client Secret',
    isPasswordType: false,
  },
  {
    key: 'tenant_id',
    display: 'Tenant ID',
    isPasswordType: false,
  }]

const MicrosoftIntegrationModal = ({ integrationData, isModalOpen, onClose, organization, onSuccess, ...props }) => {

  const [integrationDetails, setIntegrationDetails] = React.useState({});
  const [callbackDetails, setCallbackDetails] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIntegrationDetails(integrationData || {})
  },[integrationData])

  const onCloseModal = () => {
    setIntegrationDetails({});
    setCallbackDetails({});
    setIsLoading(false);
    onClose();
  }


  const onClickSave = () => {
    let payload = {};
    Object.keys(integrationDetails).map(key => {
      payload[key] = integrationDetails[key]
    });

    setIsLoading(true);
    const callbackFunction = (data) => {
      let integId, integRedirectUri;
      if (!data && callbackDetails.id) {
        integId = callbackDetails.id;
        integRedirectUri = callbackDetails.redirectUri;
      } else {
        const {
          createMicrosoftIntegration,
          updateMicrosoftIntegration
        } = data || {};
        let intgDetails = {};
        if (payload.id) {
          if (!updateMicrosoftIntegration) return;
          intgDetails = updateMicrosoftIntegration;
        } else {
          if (!createMicrosoftIntegration) return; 
          intgDetails = createMicrosoftIntegration; 
        }
        setCallbackDetails(intgDetails);
        integId = intgDetails.id;
        integRedirectUri = intgDetails.redirectUri;
      }

      const state = `{
        "id": "${integId}",
        "redirectUri": "${integRedirectUri}"
      }`;
      const stringState = encodeURI(state);
      let url = `https://login.microsoftonline.com/${integrationDetails.tenant_id}/oauth2/v2.0/authorize?client_id=${integrationDetails.client_id}
        &response_type=code
        &redirect_uri=${window.location.origin}/ms-callback
        &response_mode=query
        &scope=user.read%20openid%20profile%20offline_access%20files.read.all
      &state=${stringState}`;
      let windowPopup = window.open(url, "Cardware Microsoft Integration", "width=500,height=500,top=100px,left:200px");
      windowPopup.onclose = function (e) {
        console.log('windowPopup.close() ', e);
      };

      window.callback = async function (e) {
        console.log('windowPopup.callback() ', e);
        if (e.success == false) {
          props.dispatch(showAlert(
            {
              msg: e.message || 'Something went wrong',
              error: true
            }
          ))
          setIsLoading(false);
          return;
        } else {
          await onSuccess();
          onCloseModal();
        }

      };

      windowPopup.onbeforeunload = function (e) {
        console.log('windowPopup.onunload() ', e);
        setIsLoading(false);
      };
    }

    if (callbackDetails && callbackDetails.id) {
      callbackFunction();
      return;
    }
    let mutationQuery = createMicrosoftIntegration
    if (payload.id) {
      mutationQuery = updateMicrosoftIntegration
    }

    client.mutate({
      mutation: mutationQuery,
      variables: {
        ...payload,
        organization_id: organization.id
      }
    }).then(({ data }) => {
      callbackFunction(data);
    }).catch((err) => {
      console.log('err', err);
      setIsLoading(false);
    })
  }

  // Render Methods

  const renderInputField = (field, onChange = () => { }) => {
    return (
      <>
        {field.hasHr && <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />}
        <LabelSmall
          style={{
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          {field.display}
        </LabelSmall>
        {field.description && <ParagraphSmall>{field.description}</ParagraphSmall>}
        <Input
          value={field.value || field.initValue || ''}
          onChange={onChange}
          type={field.isPasswordType ? 'password' : 'text'}
          placeholder={field.placeholder}
          clearOnEscape
          disabled={field.disabled}
        />
        {field.limit && <ParagraphSmall>{field.value.length}/{field.limit}</ParagraphSmall>}
        {field.help && <ParagraphXSmall><a target="_blank" href={field.help.url}>{field.help.title}</a></ParagraphXSmall>}
      </>
    )
  }

  // console.log('integrationDetails', integrationDetails, integrationData)

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={onCloseModal}
        isOpen={isModalOpen}
      >
        <ModalHeader>Add Microsoft Account Integration</ModalHeader>

        <ModalBody>
          {fields.map(item => {
            const onChange = (e) => {
              setIntegrationDetails({
                ...integrationDetails,
                [item.key]: e.target.value
              })
            }
            const fieldData = {
              ...item,
              value: integrationDetails[item.key]
            }
            return renderInputField(fieldData, onChange)
          })}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={onCloseModal}
          >
            Cancel
          </ModalButton>

          <ModalButton
            isLoading={isLoading}
            onClick={onClickSave}
          >
            Sign In
          </ModalButton>

        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

MicrosoftIntegrationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func
}

MicrosoftIntegrationModal.defaultProps = {
  isModalOpen: false,
  onClose: () => { }
}

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};

export default connect(mapStateToProps)(MicrosoftIntegrationModal);