const PWA_DESIGN_OPTIONS = () => [
  {
    name: 'Navigation',
    display: 'Navigation',
    fields: [
      {
        type: 'color_picker',
        label: 'Navigation Button Color',
        placeholder: 'Enter Navigation Button Color',
        key: 'navigation_button_color',
        onChange: (changeFunc, prop) => {
          changeFunc('navigation_button_color', prop);
        }
      },
      {
        type: 'color_picker',
        label: 'Search Button Color',
        placeholder: 'Enter Search Button Color',
        key: 'search_button_color',
        onChange: (changeFunc, prop) => {
          changeFunc('search_button_color', prop);
        }
      },
      {
        type: 'color_picker',
        label: 'Navigation Bar Color',
        placeholder: 'Enter Navigation Bar Color',
        key: 'navigation_bar_color',
        onChange: (changeFunc, prop) => {
          changeFunc('navigation_bar_color', prop);
        }
      }
    ]
  }
]

export default PWA_DESIGN_OPTIONS;