import React from 'react';

class TextAreaComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange = html => {
    this.setState({
      value: html,
    });
    this.props.onChange(html);
  };

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });

    if (window.Redactor) {
      window.Redactor(`.redactor-content${this.props.textAreaKey}`, {
        focus: false,
        value: this.props.value,
        callbacks: {
          changed: html => {
            this.onChange(html);
          },
        },
      });
      if (this.props.value != '')
        window.Redactor(
          `.redactor-content${this.props.textAreaKey}`,
          'source.setCode',
          this.props.value
        );
    }
  }
  render() {
    let { value } = this.state;
    return (
      <>
        <textarea
          name={'textarea'}
          placeholder={this.props.placeholder}
          className={`redactor-content${this.props.textAreaKey}`}
          value={value}
        ></textarea>
      </>
    );
  }
}

export default TextAreaComponent;
