import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Tabs, Tab, FILL } from 'baseui/tabs-motion';
import { HeadingXLarge, ParagraphSmall } from 'baseui/typography';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { gridPaddingOverrides } from '../overrides';
import RightSidePageHeader from '../pageHeaders';
import Button from '../styledButton';
import ApiSettings from '../apiSettings/index';
import GeneralSettings from './general';
import AttributeSettings from './atrributes';
import IntegrationSettings from './integrations';
import AppEnv from './appenv';
import { FaTags } from 'react-icons/fa';
import Tags from './tags';
import Pwa from './pwa';
import LoginSpecs from './login-specs';
import Testers from './testers';
import AccessControl from './access-control';

const getTabsAndPages = (organization_settings) => {
  const { is_app_env_on, is_login_settings_on, is_manage_organization_options_settings, is_testers_on, is_api_settings_on } = organization_settings;
  let TAB_STATUS = {
    GENERAL: 'General / Appearance',
    DATA_FILTERS: 'Data Filters',
    INTEGRATIONS: 'Integrations',
    APP_ENVIRONMENTS: 'App Environments',
    TAGS: 'Tags',
    PWA: 'PWA',
    LOGIN: 'Login Settings',
    TESTERS: 'Testers',
    API: 'API',
    ACCESS_CONTROL: 'Access Control',
  };
  if (!is_app_env_on) delete TAB_STATUS['APP_ENVIRONMENTS'];
  if (!is_login_settings_on) delete TAB_STATUS['LOGIN'];
  if (!is_testers_on) delete TAB_STATUS['TESTERS'];
  if (!is_api_settings_on) delete TAB_STATUS['API'];
  if (!is_manage_organization_options_settings) delete TAB_STATUS['ACCESS_CONTROL'];
  const pageTitlesAndDescriptions = {
    [TAB_STATUS.GENERAL]: {
      title: 'General Settings',
      description: 'Manage basic settings of organization'
    },
    [TAB_STATUS.DATA_FILTERS]: {
      title: 'Data filters',
      // description: 'Add filter and specs for card'
    },
    [TAB_STATUS.INTEGRATIONS]: {
      title: 'Integrations',
      description: 'Add integrations to make cardware more advance'
    },
    [TAB_STATUS.APP_ENVIRONMENTS]: {
      title: 'App Environments',
      description: 'Add app environments'
    },
    [TAB_STATUS.TAGS]: {
      title: 'Tags',
      description: 'Add tags for card'
    },
    [TAB_STATUS.PWA]: {
      title: 'PWA',
      description: 'PWA App Setup and Deployment'
    },
    [TAB_STATUS.LOGIN]: {
      title: 'Login Settings',
      description: 'Manage Login Appearance Specifications'
    },
    [TAB_STATUS.TESTERS]: {
      title: 'Testers',
      description: 'Testers of the application'
    },
    [TAB_STATUS.API]: {
      title: 'Api',
      description: 'Add api integration'
    },
    [TAB_STATUS.ACCESS_CONTROL]: {
      title: 'Access Control',
      description: 'Manage access options for Admin'
    }
  };

  return { TAB_STATUS, pageTitlesAndDescriptions };
}

const Settings = ({
  organization,
  organization_settings,
  integrations,
  specifications,
  organization_id,
  tags,
  dispatch,
  refetch,
  toaster
}) => {
  const { TAB_STATUS, pageTitlesAndDescriptions } = getTabsAndPages(organization_settings);
  const [tab, setTab] = React.useState(TAB_STATUS.GENERAL);
  const title = pageTitlesAndDescriptions[tab]?.title;
  const description = pageTitlesAndDescriptions[tab]?.description;
  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <RightSidePageHeader
          icon={null}
          title={title}
          rightContent={
            <StatefulButtonGroup
              mode={MODE.radio}
              initialState={{ selected: 0 }}
            >
              {Object.keys(TAB_STATUS).map(el => {
                return (
                  <Button onClick={() => setTab(TAB_STATUS[el])}>
                    {TAB_STATUS[el]}
                  </Button>
                );
              })}
            </StatefulButtonGroup>
          }
          headerDescription={description}
        ></RightSidePageHeader>
        {tab == TAB_STATUS.GENERAL && (
          <Cell span={12}>
            <GeneralSettings
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            ></GeneralSettings>
          </Cell>
        )}
        {tab == TAB_STATUS.DATA_FILTERS && (
          <Cell span={12}>
            <AttributeSettings
              organization={organization}
              specifications={specifications}
              dispatch={dispatch}
              toaster={toaster}
            ></AttributeSettings>
          </Cell>
        )}
        {tab == TAB_STATUS.TAGS && (
          <Cell span={12}>
            <Tags
              organization={organization}
              tags={tags}
              toaster={toaster}
            ></Tags>
          </Cell>
        )}
        {tab == TAB_STATUS.INTEGRATIONS && (
          <Cell span={12}>
            <IntegrationSettings
              organization={organization}
              integrations={integrations}
              refetch={refetch}
              dispatch={dispatch}
            ></IntegrationSettings>
          </Cell>
        )}
        {tab == TAB_STATUS.APP_ENVIRONMENTS && (
          <Cell span={12}>
            <AppEnv
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            ></AppEnv>
          </Cell>
        )}
        {tab == TAB_STATUS.PWA && (
          <Cell span={12}>
            <Pwa
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            ></Pwa>
          </Cell>
        )}
        {tab == TAB_STATUS.LOGIN && (
          <Cell span={12}>
            <LoginSpecs
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            />
          </Cell>
        )}
        {tab == TAB_STATUS.TESTERS && (
          <Cell span={12}>
            <Testers
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            />
          </Cell>
        )}
        {tab == TAB_STATUS.API && (
          <Cell span={12}>
            <ApiSettings
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            />
          </Cell>
        )}
        {tab == TAB_STATUS.ACCESS_CONTROL && (
          <Cell span={12}>
            <AccessControl
              organization={organization}
              dispatch={dispatch}
              refetch={refetch}
            />
          </Cell>
        )}
        <Cell span={12}></Cell>
      </Grid>
    </>
  );
};

export default Settings;
