import React, { useCallback, useMemo, useState } from 'react';
import { Cell, Grid } from 'baseui/layout-grid';
import Button from '../styledButton';
import { HeadingXSmall, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import styled from '@emotion/styled';
import {Skeleton} from 'baseui/skeleton'
import 'react-toggle/style.css';
import './attribute.css';

import { updateLoginModuleSpecs as updateLoginModuleSpecsQuery, login_module_specification } from './queries'
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { useStyletron } from 'baseui';
import { login_btn_help, settings_logo_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import ImageUploadCXL from '../imageUploaderCXL';
import { organizationLogo } from '../help/imageSizeGuidelines.json';
import { getSrc } from '../../util';
import { useMutation } from '@apollo/client';
import { client } from '../../apollo/client';
import Toggle from 'react-toggle';
import { SketchPicker } from 'react-color';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';

const SaveBtnContainer = styled.div`  
  position: relative;
  float: right;
  margin-bottom: 16px;
`;

const InputContainer = styled.div`  
  padding: 24px;
  background: ${props => props.theme.colors.primaryB};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled(LabelMedium)`
  margin-bottom: 16px;
`

const ImageUploaderContainer = styled.div`
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 24px;
  padding-top: 1px;
  background: ${props => props.theme.colors.primaryB};
  margin-bottom: 24px;
`;


const ColorPicker = ({color, onChange}) => {
  const [css, theme] = useStyletron();
  return <StatefulPopover
    content={() => (
      <SketchPicker
        color={color}
        onChangeComplete={color => {
          onChange(color.hex);
        }}
      />
    )}
    placement={PLACEMENT['bottomRight']}
    returnFocus
    autoFocus
    overrides={{
      Body: {
        style: ({ $theme }) => ({
          marginTop: '50px',
        }),
      },
    }}
  >
    <div
      key={color}
      className={css({
        marginRight: '16px',
        marginBottom: '16px',
        width: 'calc((100% - 96px - 32px) / 7)',
        height: '24px',
        position: 'relative',
        float: 'left',
        borderRadius: '4px',
        border: '1px solid'
      })}
      style={{
        background: color,
      }}
    ></div>
  </StatefulPopover>
}
const LoginSpecs = (props) => {

  const {
    organization,
    dispatch,
    refetch
  } = props;

  const [background_color, setBackgroundColor] = useState('');
  const [is_background_img, setIsBackgroundImg] = useState(false);
  const [label_txt_color, setLabelTxtColor] = useState('');
  const [button_background_color, setButtonBackColor] = useState('');
  const [button_txt_color, setButtonTxtColor] = useState('');
  const [login_logo, setLoginLogo] = useState(undefined);
  const [login_logo_data, setLoginLogoData] = useState('');
  const [logo_preview_url, setLogoPreviewUrl] = useState('');
  const [background_img, setBackgroundImg] = useState(undefined);
  const [background_img_data, setBackgroundImgData] = useState('');
  const [background_img_preview_url, setBackgroundImgPreviewUrl] = useState('');
  const [dataLoading, setDataLoading] = useState(true)

  const [css, theme] = useStyletron();

  const [updateLoginModuleSpecs, { loading, data }] = useMutation(updateLoginModuleSpecsQuery);



  const setLoginConfig = useCallback((data) => {
    setBackgroundColor(data.background_color || '#000000');
    setLabelTxtColor(data.label_txt_color || '#FFFFFF');
    setButtonBackColor(data.button_background_color || '#000000');
    setButtonTxtColor(data.button_txt_color || '#FFFFFF');
    setLoginLogoData(data.login_logo_data);
    setBackgroundImgData(data.background_img_data);
    setIsBackgroundImg(data.is_background_img || false);
  }, []);

  const fetchData = () => {
    setDataLoading(true)
    client.query({ query: login_module_specification(organization.id), fetchPolicy: 'network-only' })
      .then((data) => {
        console.log('setLoginConfig', data.data.login_module_specification)
        setLoginConfig(data.data.login_module_specification);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setDataLoading(false)
      })
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  //////////////////////////////////////////////////////// Event Functions //////////////////////////////////////////////////////////////////////

  const onChangeBackgroundColor = useCallback((e) => setBackgroundColor(e.target.value), []);
  const onChangeLabelTxtColor = useCallback((e) => setLabelTxtColor(e.target.value), []);
  const onChangeBtnBackColor = (e) => setButtonBackColor(e);
  const onChangeBtnTxtColor = ((e) => setButtonTxtColor(e));
  const onChangeIsBackImage = useCallback((e) => setIsBackgroundImg(!e.target.checked), []);

  const onSave = useCallback(() => {
    updateLoginModuleSpecs({
      variables: {
        organization_id: organization.id,
        background_color,
        label_txt_color,
        button_txt_color,
        button_background_color,
        login_logo,
        login_logo_data,
        background_img,
        background_img_data,
        is_background_img
      }
    });
  }, [organization, background_img, background_color, label_txt_color, button_txt_color, button_background_color, login_logo, login_logo_data, background_img_data, is_background_img]);


  const onLoginLogoDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setLoginLogo(acceptedFiles[0]);
    setLogoPreviewUrl(URL.createObjectURL(acceptedFiles[0]));
  }, [])

  const onLoginLogoDelete = useCallback(() => {
    setLoginLogo(null)
    setLogoPreviewUrl('');
    setLoginLogoData('');
  }, [])

  const onLoginBackgroundImgDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setBackgroundImg(acceptedFiles[0]);
    setBackgroundImgPreviewUrl(URL.createObjectURL(acceptedFiles[0]));
  }, [])

  const onLoginBackgroundImgDelete = useCallback(() => {
    setBackgroundImg(null)
    setBackgroundImgPreviewUrl(null);
    setBackgroundImgData('');
  }, [])

  ///////////////////////////////////////////////////// Getter //////////////////////////////////////////////////////////////

  const getLoginLogo = useMemo(() => {
    console.log(logo_preview_url || getSrc(login_logo_data));
    return logo_preview_url || getSrc(login_logo_data);
  }, [logo_preview_url, login_logo_data])

  const getBackgroundImage = useMemo(() => {
    return background_img_preview_url || getSrc(background_img_data);
  }, [background_img_preview_url, background_img_data]);

  ///////////////////////////////////////////////////// Render Functions ////////////////////////////////////////////////////////////////////////
  const BackgroundColorInput = useMemo(() => {
    return (
      <InputContainer theme={theme}>
        <InputLabel>Background Color</InputLabel>
        {/* <Input
          value={background_color}
          onChange={onChangeBackgroundColor}
          placeholder="Backgroud Color"
          clearOnEscape
        /> */}
        <ColorPicker color={background_color}  onChange={(color) => {
          setBackgroundColor(color);
        }}/>
        <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: '0.3rem', marginTop: '1rem'}}>
          <Toggle
            id="cheese-status"
            defaultChecked={!is_background_img}
            icons={false}
            onChange={onChangeIsBackImage}
          ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem'}}>Set Background as Color</p>
        </div>
      </InputContainer>
    )
  }, [background_color, is_background_img])

  const LabelTxtColorInput = useMemo(() => {
    return (
      <InputContainer theme={theme}>
        <InputLabel>Label Text Color</InputLabel>
        {/* <Input
          value={label_txt_color}
          onChange={onChangeLabelTxtColor}
          placeholder="Label Text Color"
          clearOnEscape
        /> */}
        <ColorPicker color={label_txt_color} onChange={(color) => {
          setLabelTxtColor(color);
        }}/>
      </InputContainer>
    )
  }, [label_txt_color]);

  const BtnBackColorInput = useMemo(() => {
    return (
      <div
        className={
          css({
            marginBottom: '16px',
            width: '100%'
          })
        }
      >
        <InputLabel>Button Background Color</InputLabel>
        {/* <Input
          value={button_background_color}
          onChange={onChangeBtnBackColor}
          placeholder="Button Backgroud Color"
          clearOnEscape
        /> */}
        <ColorPicker color={button_background_color} onChange={(color) => {
          setButtonBackColor(color)
        }}></ColorPicker>
      </div>
    )
  }, [button_background_color])

  const BtnTextColorInput = useMemo(() => {
    return (
      <>
        <InputLabel>Button Text Color</InputLabel>
        {/* <Input
          value={button_txt_color}
          onChange={onChangeBtnTxtColor}
          placeholder="Button Text Color"
          clearOnEscape
        /> */}
        <ColorPicker color={button_txt_color} onChange={(color) => {
          setButtonTxtColor(color);
        }}></ColorPicker>
      </>
    )
  }, [button_txt_color])

  const GroupContainerHeader = useCallback((headerTxt, helpTxt) => {
    return (
      <div
        className={css({
          display: 'flex',
        })}
      >
        <HeadingXSmall
          style={{
            marginBottom: '16px',
          }}
        >
          {headerTxt}
        </HeadingXSmall>
        <HelpQuestionMark text={helpTxt}></HelpQuestionMark>
      </div>
    );
  }, [])

  const LoginLogoUploader = useMemo(() => {
    return (
      <ImageUploaderContainer theme={theme}>
        <ImageUploadCXL
          title="Logo"
          titleHelpText={settings_logo_help}
          previewUrls={getLoginLogo}
          isMultiple={false}
          onDrop={onLoginLogoDrop}
          onImageDelete={onLoginLogoDelete}
          imageSize={organizationLogo.size}
          imageSizeGuidelineURL={organizationLogo.url}
        />
        <ParagraphSmall
          style={{
            marginTop: '16px',
          }}
        >
          This logo will shown as logo in login page of your app.
        </ParagraphSmall>
      </ImageUploaderContainer>
    )
  }, [logo_preview_url, login_logo_data]);


  const LoginBackgroundUploader = useMemo(() => {
    return (
      <ImageUploaderContainer theme={theme}>
        <ImageUploadCXL
          title="Background Image"
          coverType='photo'
          titleHelpText={settings_logo_help}
          previewUrls={getBackgroundImage}
          isMultiple={false}
          onDrop={onLoginBackgroundImgDrop}
          onImageDelete={onLoginBackgroundImgDelete}
          imageSize={organizationLogo.size}
          imageSizeGuidelineURL={organizationLogo.url}
        />
        <ParagraphSmall
          style={{
            marginTop: '16px',
          }}
        >
          This image will be shown as login page background in your app.
        </ParagraphSmall>
      </ImageUploaderContainer>
    )
  }, [background_img_preview_url, background_img_data]);

  const SaveButton = useMemo(() => {
    return (
      <SaveBtnContainer>
        <Button
          isSelected
          isLoading={false}
          onClick={onSave}
          disabled={dataLoading}
          isLoading={loading}
        >
          Save
        </Button>
      </SaveBtnContainer>
    )
  }, [dataLoading, loading, organization, background_img, background_color, label_txt_color, button_txt_color, button_background_color, login_logo, login_logo_data, background_img_data, is_background_img])

  if (dataLoading) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{marginLeft: '2rem', marginTop: '1rem'}}>
          {new Array(5).fill('').map(item => {
            return (
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2rem'}}>
                {new Array(2).fill('').map(item => <Skeleton
                  width="600px"
                  height="300px"
                  animation
                  overrides={{
                      Root: {
                          style: {
                            marginRight: '60px'
                          },
                      },
                  }}
                />)}
              </div>
            )
          })}
      </div>
    </div>
    )
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12} align={'right'}>
        {SaveButton}
      </Cell>
      <Cell overrides={cellPaddingOverrides} span={[12, 6]}>
        {BackgroundColorInput}
        {LabelTxtColorInput}
        <InputContainer theme={theme}>
          {GroupContainerHeader('Button style', login_btn_help)}
          {BtnBackColorInput}
          {BtnTextColorInput}
        </InputContainer>
      </Cell>
      <Cell overrides={cellPaddingOverrides} span={[12, 6]}>
        {LoginLogoUploader}
        {LoginBackgroundUploader}
      </Cell>
    </Grid>
  )
}

export default LoginSpecs;